import { OnsaLogo } from '@onsaui'
import { Outlet } from 'react-router-dom'

import AnonSidebar from '../AnonSidebar/AnonSidebar'
import GeneralLayout from '../GeneralLayout/GeneralLayout'

const PlaygroundLayout: React.FC = () => {
  return <GeneralLayout sidebar={<AnonSidebar />} header={<OnsaLogo />} body={<Outlet />} />
}

export default PlaygroundLayout
