import cx from 'clsx'

const StatSlot: React.FC<{
  value: React.ReactNode
  description: React.ReactNode
  className?: string
}> = ({ value, description, className }) => {
  return (
    <div
      className={cx(
        'flex min-h-[84px] flex-row items-center justify-center rounded-[10px] bg-primary-surface px-4',
        className,
      )}
    >
      <p className="mr-3 text-4xl text-primary">{value}</p>
      <p className="text-sm">{description}</p>
    </div>
  )
}

export default StatSlot
