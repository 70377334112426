import { IconCopy, IconEdit, IconTrash } from '@onsaui/icons'
import cx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import useOutreachTemplateActions from '@/modules/icp/hooks/useOutreachTemplateActions'
import { OutreachTemplate } from '@/modules/icp/model'
import { openConfirmModal } from '@/modules/shared'
import copyToClipboardAndNotify from '@/modules/shared/helpers/copyToClipboardAndNotify'
import AnalyticsService from '@/services/analytics'

import OutreachTemplateItemEdit from './OutreachTemplateItemEdit'

const OutreachTemplateItem: React.FC<{
  icpId: string
  outreachTemplate: OutreachTemplate

  isViewOnly?: boolean
  className?: string
  isDeleteAllowed?: boolean
}> = ({ icpId, outreachTemplate, isViewOnly, isDeleteAllowed, className }) => {
  const { deleteOutreachTemplate, isDeleting } = useOutreachTemplateActions()

  const [isEditing, setIsEditing] = useState(false)
  const startEditing = () => {
    AnalyticsService.trackEvent('OutreachTemplateItem.BtnEdit.Click', {
      icpId,
      templateId: outreachTemplate.id,
    })

    setIsEditing(true)
  }
  const finishEditing = () => {
    setIsEditing(false)
  }

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const handleCopy = () => {
    AnalyticsService.trackEvent('OutreachTemplateItem.BtnCopy.Click', {
      icpId,
      templateId: outreachTemplate.id,
    })

    copyToClipboardAndNotify(outreachTemplate.message)
  }

  const handleDelete = async () => {
    const isConfirmed = await openConfirmModal({
      body: (
        <div>
          <h2 className="mb-1 font-semibold">Delete template</h2>
          <p>Are you sure you want to delete this template?</p>
        </div>
      ),
      confirmLabel: 'Delete',

      action: () => deleteOutreachTemplate(icpId, outreachTemplate.id),
    })
  }

  if (isEditing) {
    return (
      <OutreachTemplateItemEdit
        icpId={icpId}
        outreachTemplate={outreachTemplate}
        onClose={finishEditing}
        className={className}
      />
    )
  }

  const buttons = isViewOnly
    ? [
        <button
          key="copy"
          type="button"
          className="p-2 transition-all hover:text-primary"
          onClick={handleCopy}
          aria-label="Copy"
        >
          <IconCopy />
        </button>,
      ]
    : [
        <button
          key="edit"
          type="button"
          className="p-2 transition-all hover:text-primary"
          onClick={startEditing}
          aria-label="Edit"
        >
          <IconEdit />
        </button>,
        <button
          key="copy"
          type="button"
          className="p-2 transition-all hover:text-primary"
          onClick={handleCopy}
          aria-label="Copy"
        >
          <IconCopy />
        </button>,
        isDeleteAllowed ? (
          <button
            key="delete"
            type="button"
            className="p-2 transition-all hover:text-danger disabled:opacity-50"
            onClick={handleDelete}
            aria-label="Delete"
            disabled={isDeleting}
          >
            <IconTrash />
          </button>
        ) : null,
      ]

  return (
    <div className={cx('flex flex-col rounded-3xl bg-[#D8E6FF] p-5', className)}>
      <div className="mb-4 flex flex-row items-center justify-between mobile:items-start">
        <h3 className="font-semibold">{outreachTemplate.title}</h3>

        <div className="flex shrink-0 flex-row">{buttons}</div>
      </div>

      <p className="whitespace-pre-wrap">{outreachTemplate.message}</p>
    </div>
  )
}

export default OutreachTemplateItem
