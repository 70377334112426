import { Loader } from '@onsaui'
import { UseQueryResult } from '@tanstack/react-query'
import cx from 'clsx'
import React from 'react'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

interface Props<TData> {
  query: UseQueryResult<TData>
  children: (data: TData) => React.ReactNode
  renderPending?: React.ReactNode
  renderError?: ((error: Error) => React.ReactNode) | React.ReactNode
  debugPending?: boolean
  debugError?: boolean
  className?: string
}

function RenderQuery<TData>({
  query,
  renderPending,
  renderError,
  children,
  className,
  debugPending,
  debugError,
}: Props<TData>) {
  const { data, isPending, isError, error } = query

  if (isPending || debugPending) {
    if (renderPending !== undefined) {
      return renderPending
    }

    return (
      <div className={cx('flex flex-grow items-center justify-center', className)}>
        <Loader />
      </div>
    )
  }
  if (isError || debugError) {
    if (renderError !== undefined) {
      if (typeof renderError === 'function') {
        return renderError(error as Error)
      }

      return renderError
    }

    return (
      <div className={cx('flex flex-grow items-center justify-center', className)}>
        <p className="text-danger">{getErrorMessage(error)}</p>
      </div>
    )
  }
  return children(data)
}

export default RenderQuery
