import { BillingPlan, BillingPlanTier } from '@/modules/billing/model'

export const getPriceLabel = (plan: BillingPlan) => {
  if (plan.tier === BillingPlanTier.CUSTOM) {
    return 'Custom pricing'
  }
  if (plan.tier === BillingPlanTier.FREE) {
    return '$0/month'
  }

  if (!plan.monthlyPrice && plan.extraCreditPrice) {
    return `$${plan.extraCreditPrice}/per credit`
  }

  if (!plan.extraCreditPrice && plan.monthlyPrice) {
    return `$${plan.monthlyPrice}/month`
  }

  return `$${plan.monthlyPrice}/month + $${plan.extraCreditPrice}/per extra credit`
}
