/* eslint-disable no-nested-ternary */
import { BeautyLoader, Loader } from '@onsaui'

import useIcp from '@/modules/icp/hooks/useIcp'
import useIcpPoling from '@/modules/icp/hooks/useIcpPoling'
import { Icp } from '@/modules/icp/model'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { TaskStatus } from '@/modules/task/model'

const EnsureProcessedIcp: React.FC<{
  children: (icp: Icp) => React.ReactNode
  icpId: string
  loader?: React.ReactNode | ((icp: Icp) => React.ReactNode)
}> = ({ children, icpId, loader }) => {
  const { data: icp, isPending, isError, error } = useIcp(icpId)
  useIcpPoling(icpId)

  if (isPending) {
    return (
      <div className="flex flex-grow flex-col items-center justify-center">
        <Loader />
      </div>
    )
  }
  if (isError) {
    return (
      <div className="flex flex-grow flex-col items-center justify-center">
        <p className="text-danger">{getErrorMessage(error)}</p>
      </div>
    )
  }
  if (icp?.status === TaskStatus.Processing) {
    return (
      <div className="flex flex-grow flex-col items-center justify-center">
        {loader ? (
          typeof loader === 'function' ? (
            loader(icp)
          ) : (
            loader
          )
        ) : (
          <>
            <BeautyLoader />
            <p className="mt-6 text-center">
              Good things take time.
              <br />
              Preparing Ideal Customer Profile...
            </p>
          </>
        )}
      </div>
    )
  }
  if (icp?.status === TaskStatus.Completed) {
    return children(icp)
  }
  if (icp?.status === TaskStatus.Failed) {
    return (
      <div className="flex flex-grow flex-col items-center justify-center">
        <p className="text-danger">ICP generation failed</p>
      </div>
    )
  }

  return (
    <div className="flex flex-grow flex-col items-center justify-center">
      <Loader />
    </div>
  )
}

export default EnsureProcessedIcp
