import { useParams } from 'react-router-dom'

import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import TaskErrorWidget from '@/modules/task/components/TaskErrorWidget/TaskErrorWidget'
import useTask from '@/modules/task/hooks/useTask'
import useTaskPolling from '@/modules/task/hooks/useTaskPoling'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import TaskPage from '../task/TaskPage'

const WorkspaceTaskPage: React.FC = () => {
  const { taskId } = useParams()
  const taskQuery = useTask(taskId!)

  useTaskPolling(taskId!)

  return (
    <RenderQuery
      query={taskQuery}
      renderError={(error) => (
        <DefaultPageLayout className="flex flex-col items-center justify-center">
          <TaskErrorWidget error={error} />
        </DefaultPageLayout>
      )}
    >
      {(task) => {
        return (
          <TaskPage
            task={task}
            taskContext={{
              withRename: true,
              withFeedback: true,
              withShare: true,
              withRegenerate: true,
            }}
          />
        )
      }}
    </RenderQuery>
  )
}

export default WorkspaceTaskPage
