export enum BillingPlanTier {
  FREE = 'free',
  PRO = 'pro',
  CUSTOM = 'custom',
}

export interface BillingPlan {
  id: string
  name: string
  details: {
    content: string
  }[]
  monthlyPrice: number | null
  extraCreditPrice: number | null
  currency: string | null
  tier: BillingPlanTier
  minSeats: number
  maxSeats: number
  isCurrent: boolean
}
