/* eslint-disable react/no-children-prop */
import { Button, IfScreen } from '@onsaui'
import { IconCopy, IconEdit, IconEditSm } from '@onsaui/icons'
import cx from 'clsx'
import { useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import useIcpActions from '@/modules/icp/hooks/useIcpActions'
import { Icp } from '@/modules/icp/model'
import copyToClipboardAndNotify from '@/modules/shared/helpers/copyToClipboardAndNotify'

const IcpBody: React.FC<{ icp: Icp; isViewOnly?: boolean; className?: string }> = ({
  icp,
  isViewOnly,
  className,
}) => {
  const { isUpdating, updateIcp } = useIcpActions()

  const editableElRef = useRef<HTMLDivElement>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [body, setBody] = useState(icp.body)
  const startEditing = () => {
    setBody(icp.body)
    setIsEditing(true)
  }
  const handleSave = async () => {
    await updateIcp(icp.id, { body: editableElRef.current?.innerText || '' })
    setIsEditing(false)
  }

  const handleCopy = () => {
    copyToClipboardAndNotify(body)
  }

  const editButtons = (
    <>
      <Button type="button" variant="ghost" size="medium" onClick={handleCopy} aria-label="Copy">
        Copy
      </Button>

      {!isEditing && !isViewOnly ? (
        <Button
          type="button"
          variant="outlined"
          size="medium"
          onClick={startEditing}
          aria-label="Edit"
        >
          Edit <IconEditSm className="ml-1" />
        </Button>
      ) : null}
      {isEditing ? (
        <Button
          className=""
          onClick={handleSave}
          isDisabled={isUpdating}
          isLoading={isUpdating}
          size="medium"
        >
          Save
        </Button>
      ) : null}
    </>
  )

  return (
    <div className={cx('relative flex flex-col rounded-3xl bg-level1 py-5 pl-10 pr-5', className)}>
      <IfScreen mobile={<div className="mb-2 flex flex-row justify-end">{editButtons}</div>} />
      <div className="mb-6 flex flex-row items-center">
        <h1 className="text-h2 font-semibold">{icp.title}</h1>
        <div className="w-4 flex-grow" />

        <IfScreen desktop={editButtons} />
      </div>

      {!isEditing ? (
        <ReactMarkdown
          className="prose prose-base prose-indigo max-w-max prose-h1:mb-1 prose-h1:text-2xl prose-h2:mb-0 prose-h2:text-xl prose-h3:mb-0 prose-h3:text-lg prose-h4:mb-0 prose-li:text-black prose-li:marker:text-primary"
          remarkPlugins={[remarkGfm]}
          children={icp.body}
        />
      ) : (
        <div ref={editableElRef} className="whitespace-pre-wrap outline-none" contentEditable>
          {body}
        </div>
      )}
    </div>
  )
}

export default IcpBody
