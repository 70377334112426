import { useParams } from 'react-router-dom'

import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import TaskErrorWidget from '@/modules/task/components/TaskErrorWidget/TaskErrorWidget'
import useAnonTask from '@/modules/task/hooks/useAnonTask'
import useAnonTaskPoling from '@/modules/task/hooks/useAnonTaskPoling'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import TaskPage from '../task/TaskPage'

const PlaygroundTaskPage: React.FC = () => {
  const { taskId } = useParams()
  const taskQuery = useAnonTask(taskId!)

  useAnonTaskPoling(taskId!)

  return (
    <RenderQuery
      query={taskQuery}
      renderError={(error) => {
        return (
          <DefaultPageLayout className="flex flex-col items-center justify-center">
            <TaskErrorWidget error={error} />
          </DefaultPageLayout>
        )
      }}
    >
      {(task) => {
        return <TaskPage task={task} taskContext={{ withFeedback: true }} />
      }}
    </RenderQuery>
  )
}

export default PlaygroundTaskPage
