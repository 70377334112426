import { Button, InfoTooltip, notify } from '@onsaui'
import { CircleIconPlay, IconInfo, IconTrash, IllustrationReport } from '@onsaui/icons'
import cx from 'clsx'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { openSignInToContinueModal } from '@/modules/auth'
import { openUpgradeToProPromoModal } from '@/modules/billing/store/billingStore'
import useCreateTranscriptReportTask from '@/modules/task/hooks/useCreateTranscriptReportTask'
import useDemoTask from '@/modules/task/hooks/useDemoTask'
import { TaskType, TranscriptReport } from '@/modules/task/model'
import AnalyticsService from '@/services/analytics'

import CreateTaskContextField from '../CreateTaskContextField/CreateTaskContextField'
import FileDropArea from '../FileDropArea/FileDropArea'

const FILE_SIZE_LIMIT = 178_257_920 // 170 MB in bytes

const CreateTranscriptReportTaskForm: React.FC<{
  onTaskCreated?: (task: TranscriptReport.Task) => void
  className?: string
  isAnon?: boolean
}> = ({ onTaskCreated, className, isAnon }) => {
  const { workspaceId } = useParams()
  const navigate = useNavigate()

  const [file, setFile] = useState<File | undefined>(undefined)
  const handleRemoveFile = () => {
    setFile(undefined)
  }

  const [context, setContext] = useState('')
  const handleContextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContext(e.target.value)
  }

  const handleLimitExeededError = () => {
    if (isAnon) {
      openSignInToContinueModal()
    } else {
      openUpgradeToProPromoModal()
    }
  }

  const { createTask, isCreating } = useCreateTranscriptReportTask(handleLimitExeededError)

  const handleSetNewFile = (file: File | undefined) => {
    if (!file) {
      return
    }

    if (file.size > FILE_SIZE_LIMIT) {
      notify({
        message: `File size should be less than ${FILE_SIZE_LIMIT / 1_000_000} MB`,
        variant: 'error',
      })
      return
    }

    setFile(file)
  }

  const handleCreate = async (e: React.FormEvent) => {
    e.preventDefault()

    AnalyticsService.trackEvent('CreateTranscriptReportTaskWidget.BtnCreate.Click')

    const contextTrimmed = context.trim().length ? context : undefined
    const task = await createTask(file!, contextTrimmed, isAnon)

    navigate(`/${workspaceId}/t/${task.id}`)
    onTaskCreated?.(task)
  }

  const { openDemoTask, isDemoTaskLoading } = useDemoTask()

  return (
    <form className={cx('flex flex-col items-center', className)} onSubmit={handleCreate}>
      <IllustrationReport className="mb-5" />
      <h3 className="mb-2 text-center font-semibold">
        Get an Eval Report
        <InfoTooltip
          content={
            <div className="max-w-sm rounded-2xl bg-level1 p-2 text-sm font-normal shadow-card">
              <p>Upload a recording of your call or meeting for analysis and evaluation:</p>
              <p>
                If you have a transcript of your call, you can upload it in TXT format. Transcripts
                are often available from platforms like Zoom or other video conferencing tools that
                provide automatic transcription.
              </p>
            </div>
          }
        >
          <div className="ml-1 inline-flex h-6 w-6 items-center justify-center">
            <IconInfo className="inline-flex" />
          </div>
        </InfoTooltip>
      </h3>
      <p className="mb-5 text-center">Supported formats: mp3, m4a, webm, mp4, wav or&nbsp;txt</p>

      <div className="flex-grow" />

      {file ? (
        <div className="mb-4 flex w-full flex-row items-center gap-2 rounded-2xl border border-disabled p-2">
          <CircleIconPlay className="shrink-0 text-primary/60" />
          <div className="flex-grow overflow-hidden">
            <p className="truncate">{file.name}</p>
          </div>

          <button
            type="button"
            className="shrink-0 p-2 text-default transition-colors hover:text-danger"
            onClick={handleRemoveFile}
            aria-label="Remove file"
          >
            <IconTrash />
          </button>
        </div>
      ) : (
        <FileDropArea className="mb-4 h-24 w-full" file={file} onFileSelected={handleSetNewFile} />
      )}

      <CreateTaskContextField
        className="mb-4 w-full"
        value={context}
        onChange={handleContextChange}
      />

      <Button
        type="submit"
        className="w-full"
        isDisabled={!file || isCreating}
        isLoading={isCreating}
      >
        Get Report
      </Button>

      <Button
        className="mt-1"
        variant="ghost"
        // eslint-disable-next-line react/jsx-no-bind
        onClick={openDemoTask.bind(null, TaskType.TranscriptReport)}
        isDisabled={isDemoTaskLoading}
      >
        Try Demo Call for Free
      </Button>
    </form>
  )
}

export default CreateTranscriptReportTaskForm
