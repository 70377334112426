import { cva, type VariantProps } from 'class-variance-authority'
import cx from 'clsx'
import React, { useCallback, useEffect, useId } from 'react'

import { useShakeAnim } from '../../hooks/useShakeAnim/useShakeAnim'

const inputVariants = cva(
  'transition-colors py-2 h-[90px] px-4 text-base outline-none w-full resize-none placeholder:whitespace-pre-wrap',
  {
    variants: {
      isDisabled: {
        true: 'cursor-not-allowed',
        false: '',
      },
      isError: {
        true: '',
        false: '',
      },
      variant: {
        default: 'bg-transparent rounded-[10px] border',
        'primary-filled': 'bg-primary-surface rounded-[10px] border',
      },
    },
    compoundVariants: [
      {
        variant: ['default'],
        isDisabled: false,
        isError: false,
        className: 'border-default text-default focus:border-primary',
      },
      {
        variant: ['default'],
        isDisabled: true,
        isError: false,
        className: 'border-disabled text-disabled placeholder:text-disabled',
      },
      {
        variant: ['default', 'primary-filled'],
        isError: true,
        className: 'border-danger/60 focus:border-danger',
      },
      {
        variant: ['primary-filled'],
        isDisabled: false,
        isError: false,
        className: 'border-primary-surface text-default focus:border-primary',
      },
      {
        variant: ['primary-filled'],
        isDisabled: true,
        isError: false,
        className: 'border-primary-surface text-disabled placeholder:text-disabled',
      },
    ],
    defaultVariants: {
      variant: 'default',
      isDisabled: false,
      isError: false,
    },
  },
)

export interface TextAreaProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof inputVariants> {
  isError?: boolean
  errorMessage?: string
  shakeOnError?: boolean
  inputClassName?: string
  autoHeight?: {
    enabled: boolean
    minLines?: number
    maxLines?: number
  }
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      variant,
      size,
      id: idProp,
      name,
      inputClassName,
      isDisabled = false,
      isError = false,
      errorMessage,
      shakeOnError = false,
      autoHeight,
      ...props
    },
    ref,
  ) => {
    const generatedId = useId()
    const id = idProp ?? generatedId

    const [shakeClass, shakeIt] = useShakeAnim()
    useEffect(() => {
      if (isError && shakeOnError) {
        shakeIt()
      }
    }, [shakeOnError, isError, shakeIt])

    const errorProps = isError ? { 'aria-invalid': true, 'aria-describedby': `${id}-error` } : {}

    const applyAutoHeight = useCallback(
      (el: HTMLTextAreaElement) => {
        const lineHeight = 24
        const minLines = autoHeight?.minLines ?? 1
        const maxLines = autoHeight?.maxLines ?? 99

        const minHeight = Math.max(48, minLines * lineHeight + 8 * 2 + 2)
        const maxHeight = maxLines * lineHeight + 8 * 2 + 2

        el.style.height = '0px'
        let height = el.scrollHeight + 2
        if (height < minHeight) {
          height = minHeight
        } else if (height > maxHeight) {
          height = maxHeight
        }

        el.style.height = `${height}px`
      },
      [autoHeight],
    )

    useEffect(() => {
      if (!id || !autoHeight?.enabled) {
        return
      }

      const el = document.getElementById(id)
      if (el) {
        applyAutoHeight(el as HTMLTextAreaElement)
      }
    }, [id, autoHeight, applyAutoHeight])

    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (autoHeight?.enabled) {
        applyAutoHeight(e.target)
      }

      props.onInput?.(e)
    }

    useEffect(() => {
      if (autoHeight?.enabled) {
        const el = document.getElementById(id!)
        if (el) {
          applyAutoHeight(el as HTMLTextAreaElement)
        }
      }
    }, [id, props.value, applyAutoHeight, autoHeight])

    return (
      <div className={cx('flex align-top', shakeClass, className)}>
        <textarea
          ref={ref}
          id={id}
          name={name}
          className={cx(inputVariants({ variant, isDisabled, isError }), inputClassName)}
          disabled={isDisabled ?? undefined}
          {...errorProps}
          {...props}
          onInput={handleInput}
        />
        {errorMessage && (
          <span id={`${id}-error`} className="mb-1 block text-xs text-danger">
            {errorMessage}
          </span>
        )}
      </div>
    )
  },
)
TextArea.displayName = 'TextArea'

export { TextArea }
