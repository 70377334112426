import { Button } from '@onsaui'
import { useNavigate, useParams } from 'react-router-dom'

import useIcpLeadsActions from '@/modules/icp/hooks/useIcpLeadsActions'

const LeadMemoButton: React.FC<{
  icpId: string
  leadId: string
  memoId?: string
  className?: string
}> = ({ icpId, leadId, memoId, className }) => {
  const navigate = useNavigate()
  const { workspaceId } = useParams()
  const { createIcpLeadMemo } = useIcpLeadsActions()

  const handleCreateMemo = async () => {
    const taskId = await createIcpLeadMemo(icpId, leadId)
    navigate(`/${workspaceId}/t/${taskId}`)
  }
  const handleOpenMemo = () => {
    navigate(`/${workspaceId}/t/${memoId}`)
  }

  return (
    <Button
      variant="outlined"
      onClick={memoId ? handleOpenMemo : handleCreateMemo}
      className={className}
      size="small"
    >
      {memoId ? 'Open Memo' : 'Create Memo'}
    </Button>
  )
}

export default LeadMemoButton
