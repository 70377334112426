import { Button } from '@onsaui'
import {
  IconCheckCircleFilled,
  IconInfoSm,
  IconProse,
  IconReferenceLeadsStep,
  IconTemplateStep,
} from '@onsaui/icons'
import cx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'

import useIcp from '@/modules/icp/hooks/useIcp'
import useIcpActions from '@/modules/icp/hooks/useIcpActions'
import { ICP_GOOD_SCORE, icpHelpers, IcpStage } from '@/modules/icp/model'
import { TaskStatus } from '@/modules/task/model'
import AnalyticsService from '@/services/analytics'

interface IcpStageConfig {
  label: string
  Icon: React.FC
  description: string
  route: string | null
  nextStage: IcpStage | null
}

const StageConfig: Record<IcpStage, IcpStageConfig> = {
  [IcpStage.SetContext]: {
    label: 'Review ICP',
    Icon: IconProse,
    description:
      'Add the context, the more details you provide, the better the results. Add more specifics to refine your Ideal Customer Profile (ICP) for greater accuracy and effectiveness.',
    route: icpHelpers.getIcpStageRoute(IcpStage.SetContext),
    nextStage: IcpStage.ReferenceLeads,
  },
  [IcpStage.ReferenceLeads]: {
    label: 'Score Prospects',
    Icon: IconReferenceLeadsStep,
    description: `Approve at least 10 references with an average score above ${ICP_GOOD_SCORE} to improve your Ideal Customer Profile (ICP).  Providing insights on why some prospects don't match your ICP will directly improve the relevance of our lead search.`,
    route: icpHelpers.getIcpStageRoute(IcpStage.ReferenceLeads),
    nextStage: IcpStage.MessageTemplates,
  },
  [IcpStage.MessageTemplates]: {
    label: 'Approve Messaging',
    Icon: IconTemplateStep,
    description:
      'Review and approve the template to ensure this align with your needs and tone of voice. This template will be used to generate outreach messages.',
    route: icpHelpers.getIcpStageRoute(IcpStage.MessageTemplates),
    nextStage: IcpStage.Confirmation,
  },
  [IcpStage.Confirmation]: {
    label: 'Finalize',
    Icon: IconTemplateStep,
    description:
      "Review your Ideal Customer Profile (ICP), if any adjustments are needed, you can go back to the previous step and add more details to ensure it's accurate and aligned with your needs.",
    route: icpHelpers.getIcpStageRoute(IcpStage.Confirmation),
    nextStage: IcpStage.Finalized,
  },
  [IcpStage.Finalized]: {
    label: 'Finalized',
    Icon: IconTemplateStep,
    description:
      'Your Ideal Customer Profile (ICP) is finalized and ready to be used for lead generation.',
    route: null,
    nextStage: null,
  },
}

const EditableStages = [IcpStage.SetContext, IcpStage.ReferenceLeads, IcpStage.MessageTemplates]

const IcpStageWidget: React.FC<{ icpId: string; currentStage: IcpStage; className?: string }> = ({
  icpId,
  currentStage,
  className,
}) => {
  const navigate = useNavigate()
  const stageConfig = StageConfig[currentStage]

  const { data: icp, isPending, isError, error } = useIcp(icpId)

  const { updateIcpStage, isUpdating } = useIcpActions()

  const handleNextStage = async () => {
    if (!stageConfig.nextStage) {
      return
    }

    AnalyticsService.trackEvent('IcpStageWidget.BtnLooksGood.Click', {
      currentStage,
      nextStage: stageConfig.nextStage,
    })

    const nextStageRoute = StageConfig[stageConfig.nextStage].route
    await updateIcpStage(icpId, stageConfig.nextStage)
    navigate(`../${nextStageRoute}`)
  }
  const handleFinalize = async () => {
    AnalyticsService.trackEvent('IcpStageWidget.BtnLooksGood.Click', {
      currentStage,
      nextStage: IcpStage.Finalized,
    })

    await updateIcpStage(icpId, IcpStage.Finalized)
    navigate(`../..`)
  }

  return (
    <div className={cx('rounded-3xl bg-level1 p-5', className)}>
      <div className={cx('flex flex-row items-center gap-6 mobile:flex-col mobile:items-start')}>
        <div className="flex flex-grow flex-row gap-4 mobile:flex-col">
          {EditableStages.map((stage) => {
            const { label, Icon, route } = StageConfig[stage]

            const isCompleted = icp ? icpHelpers.isStageCompleted(stage, icp.stage) : false
            const isCurrentStage = stage === currentStage

            const stageStep = (
              <div
                key={stage}
                className={cx(
                  'flex flex-row items-center gap-2',
                  isCompleted || isCurrentStage ? 'text-primary' : 'text-disabled',
                )}
              >
                <div
                  className={cx('relative rounded-full p-2 text-white', {
                    'bg-primary': isCompleted || isCurrentStage,
                    'bg-disabled': !isCompleted && !isCurrentStage,
                  })}
                >
                  <Icon />
                  {isCompleted ? (
                    <IconCheckCircleFilled className="absolute -right-1.5 bottom-0 h-4 w-4 text-success" />
                  ) : null}
                </div>
                <p>{label}</p>
              </div>
            )

            return isCompleted || stage === icp?.stage ? (
              <Link to={`../${route}`} key={stage}>
                {stageStep}
              </Link>
            ) : (
              stageStep
            )
          })}
        </div>

        {icp && EditableStages.includes(currentStage) ? (
          <Button
            className="mobile:w-full"
            onClick={handleNextStage}
            isDisabled={isUpdating || icp.status !== TaskStatus.Completed}
            isLoading={isUpdating}
          >
            Looks Good
          </Button>
        ) : null}
        {icp && currentStage === IcpStage.Confirmation ? (
          <Button
            className="mobile:w-full"
            onClick={handleFinalize}
            isDisabled={isUpdating || icp.status !== TaskStatus.Completed}
            isLoading={isUpdating}
          >
            Finalize
          </Button>
        ) : null}
      </div>
      <hr className="my-4 border-disabled" />
      <div className="flex flex-row items-start">
        <IconInfoSm className="mr-3 h-5 w-5 shrink-0 text-disabled" />
        <p className="text-secondary">{stageConfig.description}</p>
      </div>
    </div>
  )
}

export default IcpStageWidget
