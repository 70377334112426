import dayjs from 'dayjs'

import { UserMe } from '@/modules/user/model'

import { UserMeResponse } from './userApiInterfaces'

export const userMeAdapter = (userResponse: UserMeResponse): UserMe => {
  const user: UserMe = {
    firstName: userResponse.firstName,
    lastName: userResponse.lastName,
    avatarUrl: userResponse.avatarUrl ?? undefined,
    email: userResponse.email,
    languageCode: userResponse.languageCode,
    role: userResponse.role,
    planName: userResponse.planName,
    planId: userResponse.planId,
    planTier: userResponse.planTier,
    creditsLeft: userResponse.creditsLeft,
    creditsResetDate: dayjs(userResponse.creditsResetDate),
    linkedInConnection: userResponse.linkedInConnection ?? undefined,
  }

  return user
}
