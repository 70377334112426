import { ICP_GOOD_SCORE } from './icpConstants'
import { IcpStage } from './icpInterfaces'
import { IcpLead } from './icpLeadsInterfaces'

export const getIcpStageRoute = (stage: IcpStage) => {
  const stageRouteMap = {
    [IcpStage.SetContext]: 'context',
    [IcpStage.ReferenceLeads]: 'leads',
    [IcpStage.MessageTemplates]: 'templates',
    [IcpStage.Confirmation]: 'confirmation',
  }

  // @ts-expect-error untyped map
  const route = stageRouteMap[stage]
  if (!route) {
    throw new Error(`Route is undefined for stage: ${stage}`)
  }

  return route
}

export const isStageCompleted = (currentStage: IcpStage, maxStage: IcpStage) => {
  const StageOrder = [
    IcpStage.SetContext,
    IcpStage.ReferenceLeads,
    IcpStage.MessageTemplates,
    IcpStage.Confirmation,
    IcpStage.Finalized,
  ]

  return StageOrder.indexOf(maxStage) > StageOrder.indexOf(currentStage)
}

export const getLeadsScore = (leads: IcpLead[]) => {
  if (!leads.length) {
    return { score: 0, isGood: false }
  }

  const TOP_SCORES_COUNT = 10

  let score = 0
  const topScores = leads.reduce<number[]>((acc, lead) => {
    if (acc.length < TOP_SCORES_COUNT) {
      acc.push(lead.score)
    } else {
      const minScore = Math.min(...acc)
      if (lead.score > minScore) {
        acc[acc.indexOf(minScore)] = lead.score
      }
    }

    return acc
  }, [])
  score = topScores.reduce((acc, score) => acc + score, 0) / topScores.length

  return { score: Math.round(score * 10) / 10, isGood: score >= ICP_GOOD_SCORE }
}

export const getLeadsBatches = (leads: IcpLead[]) => {
  const batchesMap = leads.reduce<Record<string, IcpLead[]>>((acc, lead) => {
    if (!acc[lead.batchId]) {
      acc[lead.batchId] = []
    }
    acc[lead.batchId].push(lead)

    return acc
  }, {})

  const batches = Object.keys(batchesMap).map((batchId) => batchesMap[batchId])
  batches.sort((a, b) => a[0].createdAt.diff(b[0].createdAt))

  batches.forEach((batch) => {
    batch.sort((a, b) => a.createdAt.diff(b.createdAt))
  })

  return batches
}
