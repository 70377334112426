import { useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { InternalTaskType, TaskType } from '@/modules/task/model'

import cm from './useTaskHighlight.module.css'

const useTaskHighlight = () => {
  const isReadyRef = useRef(false)
  useEffect(() => {
    setTimeout(() => {
      isReadyRef.current = true
    }, 300)
  }, [])

  const [searchParams, setSearchParams] = useSearchParams()
  const [highlight, setHighlight] = useState('')
  useEffect(() => {
    const highlightParam = searchParams.get('highlight')
    if (highlightParam) {
      setSearchParams((prev) => {
        prev.delete('highlight')
        return prev
      })

      if (isReadyRef.current) {
        setHighlight(highlightParam)
      }
    }
  }, [searchParams, setSearchParams])
  useEffect(() => {
    if (highlight) {
      setTimeout(() => {
        setHighlight('')
      }, 600)
    }
  }, [highlight])

  const highlights = useMemo(() => {
    return {
      [TaskType.PersonMemo]:
        highlight === TaskType.PersonMemo || highlight === 'all' ? cm.highlight : '',
      [TaskType.TranscriptReport]:
        highlight === TaskType.TranscriptReport || highlight === 'all' ? cm.highlight : '',
      [InternalTaskType.CreateIcp]:
        highlight === InternalTaskType.CreateIcp || highlight === 'all' ? cm.highlight : '',
    }
  }, [highlight])

  return highlights
}

export default useTaskHighlight
