import { InternalTaskType, TaskType } from '@/modules/task/model'
import { Workspace, WorkspaceMinimal } from '@/modules/workspace'

import { WorkspaceMinimalResponse, WorkspaceResponse } from './workspaceApiInterfaces'

export const workspaceMinimalAdapter = (workspaceResponse: WorkspaceMinimalResponse) => {
  const workspace: WorkspaceMinimal = {
    id: workspaceResponse.id,
    name: workspaceResponse.name,

    personalInfo: workspaceResponse.personalInfo ?? '',
    companyInfo: workspaceResponse.companyInfo ?? '',
    defaultCompanyInfo: workspaceResponse.defaultCompanyInfo ?? '',
  }

  return workspace
}

export const workspaceAdapter = (workspaceResponse: WorkspaceResponse) => {
  const workspace: Workspace = {
    id: workspaceResponse.id,
    name: workspaceResponse.name,

    personalInfo: workspaceResponse.personalInfo ?? '',
    companyInfo: workspaceResponse.companyInfo ?? '',
    defaultCompanyInfo: workspaceResponse.defaultCompanyInfo ?? '',

    availableTasks: workspaceResponse.availableTasks.map((task) => ({
      type: task.type as TaskType | InternalTaskType,
      creditsCost: task.creditsCost,
    })),
  }

  return workspace
}
