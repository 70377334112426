import { useQuery } from '@tanstack/react-query'

import { icpLeadsApi } from '@/modules/icp/api'
import { TaskStatus } from '@/modules/task/model'

const useIcpCampaignLeads = (icpId: string) => {
  return useQuery({
    queryKey: ['icpCampaignLeads', icpId],
    queryFn: () => {
      return icpLeadsApi.getIcpLeads(icpId)
    },
    refetchInterval: (query) => {
      const status = query.state.data?.status
      const isNeedPolling =
        status && status !== TaskStatus.Completed && status !== TaskStatus.Failed
      if (isNeedPolling) {
        return 3000
      }
    },
  })
}

export default useIcpCampaignLeads
