import cx from 'clsx'

import cm from './BeautyLoader.module.css'
import { ReactComponent as Illustration } from './Illustration.svg'

const getAngleVector = (shift: number, angle: number) => {
  const radian = angle * (Math.PI / 180)
  return {
    x: shift * (Math.round(Math.cos(radian) * 1000) / 1000),
    y: shift * (Math.round(Math.sin(radian) * 1000) / 1000),
  }
}

const BeautyLoader: React.FC<{ className?: string }> = ({ className }) => {
  const count = 8

  const size = 60
  const radius = 8
  const centerXY = size / 2
  const lineWidth = 10
  const lineHeight = 4

  const duration = 1.6

  return (
    <div className={cx('relative inline-flex h-max w-max shrink-0', className)}>
      <div
        className="absolute left-1/2 top-[82px] -translate-x-1/2 -translate-y-1/2"
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <style>{`:root { --ss-width: ${lineWidth}px }`}</style>
        {Array.from({ length: count }).map((_, i) => {
          const angle = (360 / count) * i
          const { x, y } = getAngleVector(radius, angle)

          return (
            <div
              key={i}
              className={cx('absolute left-0 top-0 rounded-full bg-current', cm.segment)}
              style={{
                width: `${lineWidth}px`,
                height: `${lineHeight}px`,
                transform: `translate(${x + centerXY}px, ${
                  y + centerXY - lineHeight / 2
                }px) rotate(${angle}deg)`,
                transformOrigin: 'left center',
                animationDelay: `${i * (duration / count)}s`,
                animationDuration: `${duration}s`,
              }}
            />
          )
        })}
      </div>
      <Illustration />
    </div>
  )
}

export default BeautyLoader
