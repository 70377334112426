import { notify } from '@onsaui'
import { useState } from 'react'

import { outreachActions } from '@/modules/icp'
import { OutreachTemplate } from '@/modules/icp/model'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import AnalyticsService from '@/services/analytics'

const useOutreachTemplateActions = () => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const createOutreachTemplate = async (
    icpId: string,
    title: string,
    message: string,
    position: number = 100,
  ) => {
    AnalyticsService.trackEvent('OutreachTemplateActions.CreateTemplate.Start', { icpId })
    setIsCreating(true)

    try {
      const template = await outreachActions.createOutreachTemplate(icpId, title, message, position)
      AnalyticsService.trackEvent('OutreachTemplateActions.CreateTemplate.Success', {
        icpId,
        templateId: template.id,
      })
      return template
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('OutreachTemplateActions.CreateTemplate.Error', {
        icpId,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsCreating(false)
    }
  }

  const updateOutreachTemplate = async (
    icpId: string,
    templateId: string,
    changes: Partial<OutreachTemplate>,
  ) => {
    AnalyticsService.trackEvent('OutreachTemplateActions.UpdateTemplate.Start', {
      icpId,
      templateId,
    })
    setIsUpdating(true)

    try {
      const template = await outreachActions.updateOutreachTemplate(icpId, templateId, changes)
      AnalyticsService.trackEvent('OutreachTemplateActions.UpdateTemplate.Success', {
        icpId,
        templateId,
      })
      return template
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('OutreachTemplateActions.UpdateTemplate.Error', {
        icpId,
        templateId,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsUpdating(false)
    }
  }

  const deleteOutreachTemplate = async (
    icpId: string,
    templateId: string,
    onDeleted?: () => void,
  ) => {
    AnalyticsService.trackEvent('OutreachTemplateActions.DeleteTemplate.Start', {
      icpId,
      templateId,
    })
    setIsDeleting(true)

    let isDeleted = false
    try {
      await outreachActions.deleteOutreachTemplate(icpId, templateId)
      AnalyticsService.trackEvent('OutreachTemplateActions.DeleteTemplate.Success', {
        icpId,
        templateId,
      })

      isDeleted = true
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('OutreachTemplateActions.DeleteTemplate.Error', {
        icpId,
        templateId,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsDeleting(false)
    }

    if (isDeleted) {
      onDeleted?.()
    }
  }

  return {
    deleteOutreachTemplate,
    isDeleting,
    createOutreachTemplate,
    isCreating,
    updateOutreachTemplate,
    isUpdating,
  }
}

export default useOutreachTemplateActions
