import { Button, CancellableActionButton, Modal } from '@onsaui'
import { IconTrash } from '@onsaui/icons'
import cx from 'clsx'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import useDeleteTask from '@/modules/task/hooks/useDeleteTask'
import useTasks from '@/modules/task/hooks/useTasks'
import { TaskInfo, TaskStatus, TaskType } from '@/modules/task/model'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import CreatePersonMemoTaskForm from '../CreatePersonMemoTaskForm/CreatePersonMemoTaskForm'
import CreateTranscriptReportTaskForm from '../CreateTranscriptReportTaskForm/CreateTranscriptReportTaskForm'

const SidebarTaskList: React.FC<{
  selectedTaskId?: string
  taskTypeFilter?: TaskType
  className?: string
}> = ({ selectedTaskId, taskTypeFilter, className }) => {
  const { workspaceId } = useParams()
  const tasksQuery = useTasks()

  const { deleteTask } = useDeleteTask()
  const handleDeleteTask = (task: TaskInfo) => {
    deleteTask(task.id)
  }

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true)
  }
  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false)
  }

  return (
    <RenderQuery query={tasksQuery} className={cx('min-h-[200px]', className)}>
      {(tasks) => {
        const allowedTasks = [TaskType.TranscriptReport, TaskType.PersonMemo]
        const filteredTasks = tasks.filter(
          (task) =>
            allowedTasks.includes(task.type) && (!taskTypeFilter || task.type === taskTypeFilter),
        )

        if (!filteredTasks.length) {
          return (
            <div
              className={cx('flex min-h-[120px] flex-col items-center justify-center', className)}
            >
              <Modal
                className="flex max-w-md flex-col items-center"
                isOpen={isCreateModalOpen}
                onClose={handleCloseCreateModal}
              >
                {taskTypeFilter === TaskType.PersonMemo ? (
                  <CreatePersonMemoTaskForm />
                ) : taskTypeFilter === TaskType.TranscriptReport ? (
                  <CreateTranscriptReportTaskForm />
                ) : (
                  <div>Unknown task type...</div>
                )}
              </Modal>

              <p className="text-secondary">
                Your {taskTypeFilter === TaskType.PersonMemo ? 'memos' : 'reports'} will appear here
              </p>
              <Button className="mt-2" size="small" onClick={handleOpenCreateModal}>
                {taskTypeFilter === TaskType.PersonMemo
                  ? 'Create Person Memo'
                  : 'Create Eval Report'}
              </Button>
            </div>
          )
        }

        return (
          <ul className={cx('flex flex-col gap-2', className)}>
            {filteredTasks.map((task) => {
              const isError = task.status === TaskStatus.Failed

              return (
                <li
                  key={task.id}
                  className="group relative flex flex-row items-center gap-2 rounded-2xl p-2 hover:bg-disabled-surface"
                >
                  <Link
                    to={`/${workspaceId}/t/${task.id}`}
                    className={cx(
                      'sidebar-int flex min-h-8 w-full flex-row items-center gap-2 text-left',
                      {
                        'text-primary': task.id === selectedTaskId && !isError,
                        'text-danger': isError,
                      },
                    )}
                  >
                    <div className="flex flex-col justify-center overflow-hidden">
                      <p className="line-clamp-2">
                        {task.status === TaskStatus.Failed ? 'Failed Task' : task.title}
                      </p>
                    </div>
                  </Link>
                  <CancellableActionButton
                    className="text-secondary opacity-0 transition-all hover:text-danger group-hover:opacity-100"
                    color="danger"
                    onAction={() => handleDeleteTask(task)}
                  >
                    <IconTrash />
                  </CancellableActionButton>
                </li>
              )
            })}
          </ul>
        )
      }}
    </RenderQuery>
  )
}

export default SidebarTaskList
