import { Button } from '@onsaui'
import { IconPlusSm } from '@onsaui/icons'
import { Link, Outlet, useParams } from 'react-router-dom'

import GlobalEventService from '@/services/globalEvent'

import GeneralLayout from '../GeneralLayout/GeneralLayout'
import WorkspaceHeader from '../WorkspaceHeader/WorkspaceHeader'
import WorkspaceSidebar from '../WorkspaceSidebar/WorkspaceSidebar'

const WorkspaceLayout: React.FC = () => {
  const { workspaceId } = useParams()

  return (
    <GeneralLayout
      sidebar={<WorkspaceSidebar />}
      sidebarHeader={
        <>
          <div className="flex-grow" />
          <Button size="medium" asChild>
            <Link to={`/${workspaceId}?highlight=all`}>
              <span>New Task</span>
              <IconPlusSm className="ml-2" />
            </Link>
          </Button>
        </>
      }
      header={<WorkspaceHeader />}
      body={<Outlet />}
    />
  )
}

export default WorkspaceLayout
