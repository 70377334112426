import cx from 'clsx'
import React from 'react'

import useIcpReferenceLeads from '@/modules/icp/hooks/useIcpReferenceLeads'
import { IcpLead } from '@/modules/icp/model'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

const EnsureIcpLead: React.FC<{
  icpId: string
  leadId: string
  children: (icpLead: IcpLead) => React.ReactNode
  className?: string
}> = ({ icpId, leadId, children, className }) => {
  const referenceLeadsQuery = useIcpReferenceLeads(icpId)

  return (
    <RenderQuery query={referenceLeadsQuery} className={className}>
      {(leadsWithStatus) => {
        const lead = leadsWithStatus.leads.find((l) => l.id === leadId)
        if (!lead) {
          return (
            <div className={cx('flex items-center justify-center', className)}>
              <p className="text-danger">Lead not found</p>
            </div>
          )
        }
        return children(lead)
      }}
    </RenderQuery>
  )
}

export default EnsureIcpLead
