import { OutreachStageStatus, OutreachStageType } from './outreachInterfaces'

export const OutreachStatusLabelMap: Record<
  OutreachStageType,
  Record<OutreachStageStatus, string>
> = {
  [OutreachStageType.Connect]: {
    [OutreachStageStatus.Scheduled]: 'Connection scheduled',
    [OutreachStageStatus.Pending]: 'Awaiting connection acceptance',
    [OutreachStageStatus.Positive]: 'Connected',
    [OutreachStageStatus.Negative]: 'No response',
    [OutreachStageStatus.StoppedByUser]: 'Outreach stopped',
    [OutreachStageStatus.StoppedByOnsa]: 'Outreach stopped',
  },

  [OutreachStageType.Outreach]: {
    [OutreachStageStatus.Scheduled]: 'Initial message scheduled',
    [OutreachStageStatus.Pending]: 'Waiting for response',
    [OutreachStageStatus.Positive]: 'Replied',
    [OutreachStageStatus.Negative]: 'No response',
    [OutreachStageStatus.StoppedByUser]: 'Outreach stopped',
    [OutreachStageStatus.StoppedByOnsa]: 'Outreach stopped',
  },

  [OutreachStageType.FollowUp1]: {
    [OutreachStageStatus.Scheduled]: 'Follow up #1 scheduled',
    [OutreachStageStatus.Pending]: 'Waiting for response',
    [OutreachStageStatus.Positive]: 'Replied',
    [OutreachStageStatus.Negative]: 'No response',
    [OutreachStageStatus.StoppedByUser]: 'Outreach stopped',
    [OutreachStageStatus.StoppedByOnsa]: 'Outreach stopped',
  },
  [OutreachStageType.FollowUp2]: {
    [OutreachStageStatus.Scheduled]: 'Follow up #2 scheduled',
    [OutreachStageStatus.Pending]: 'Waiting for response',
    [OutreachStageStatus.Positive]: 'Replied',
    [OutreachStageStatus.Negative]: 'No response',
    [OutreachStageStatus.StoppedByUser]: 'Outreach stopped',
    [OutreachStageStatus.StoppedByOnsa]: 'Outreach stopped',
  },
}

export const OutreachStatusDescriptionMap: Record<
  OutreachStageType,
  Record<OutreachStageStatus, string | null>
> = {
  [OutreachStageType.Connect]: {
    [OutreachStageStatus.Scheduled]: 'A connection request on LinkedIn has been scheduled.',
    [OutreachStageStatus.Pending]:
      'Waiting for the person to accept the LinkedIn connection request.',
    [OutreachStageStatus.Positive]: 'The LinkedIn connection request has been accepted.',
    [OutreachStageStatus.Negative]: 'The person did not accept the LinkedIn connection request.',
    [OutreachStageStatus.StoppedByUser]: 'The outreach process stopped by the user.',
    [OutreachStageStatus.StoppedByOnsa]: 'Seems you have already connected with this person.',
  },

  [OutreachStageType.Outreach]: {
    [OutreachStageStatus.Scheduled]: 'The first outreach message has been scheduled.',
    [OutreachStageStatus.Pending]: 'Waiting for a response to the first outreach message.',
    [OutreachStageStatus.Positive]: null,
    [OutreachStageStatus.Negative]: 'No response to the first outreach message was received.',
    [OutreachStageStatus.StoppedByUser]: 'The outreach process stopped by the user.',
    [OutreachStageStatus.StoppedByOnsa]: 'Seems you have already connected with this person.',
  },

  [OutreachStageType.FollowUp1]: {
    [OutreachStageStatus.Scheduled]:
      'The first follow-up message has been scheduled after no response.',
    [OutreachStageStatus.Pending]: 'Waiting for a response to the first follow-up message.',
    [OutreachStageStatus.Positive]: null,
    [OutreachStageStatus.Negative]: 'No response to the first follow-up message was received.',
    [OutreachStageStatus.StoppedByUser]: 'The outreach process stopped by the user.',
    [OutreachStageStatus.StoppedByOnsa]: 'Seems you have already connected with this person.',
  },

  [OutreachStageType.FollowUp2]: {
    [OutreachStageStatus.Scheduled]:
      'The second follow-up message has been scheduled after no response to the first follow-up.',
    [OutreachStageStatus.Pending]: 'Waiting for a response to the second follow-up message.',
    [OutreachStageStatus.Positive]: null,
    [OutreachStageStatus.Negative]: 'No response to the second follow-up message was received.',
    [OutreachStageStatus.StoppedByUser]: 'The outreach process stopped by the user.',
    [OutreachStageStatus.StoppedByOnsa]: 'Seems you have already connected with this person.',
  },
}

export const OutreachStageNameMap: Record<OutreachStageType, string> = {
  [OutreachStageType.Connect]: 'Connection',
  [OutreachStageType.Outreach]: 'Initial message',
  [OutreachStageType.FollowUp1]: 'Follow up #1',
  [OutreachStageType.FollowUp2]: 'Follow up #2',
}

export const getOutreachStatusLabel = (stage: OutreachStageType, status: OutreachStageStatus) => {
  return OutreachStatusLabelMap[stage][status]
  // return 'asd'
}

export const getOutreachStageName = (stage: OutreachStageType) => {
  return OutreachStageNameMap[stage]
}

export const getOutreachStatusDescription = (
  stage: OutreachStageType,
  status?: OutreachStageStatus,
) => {
  if (!status) {
    return null
  }

  return OutreachStatusDescriptionMap[stage][status]
}
