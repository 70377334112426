import { useQuery } from '@tanstack/react-query'

import { icpApi } from '@/modules/icp/api'

const useIcpStatistics = (icpId: string) => {
  return useQuery({
    queryKey: ['icpStatistics', icpId],
    queryFn: () => {
      return icpApi.getIcpStatistics(icpId)
    },
  })
}

export default useIcpStatistics
