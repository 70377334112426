import { Button, CancellableActionButton, Modal } from '@onsaui'
import { IconTrash } from '@onsaui/icons'
import cx from 'clsx'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import useIcpActions from '@/modules/icp/hooks/useIcpActions'
import useIcps from '@/modules/icp/hooks/useIcps'
import { IcpMinimal } from '@/modules/icp/model'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import CreateIcpForm from '../CreateIcpForm/CreateIcpForm'

const SidebarIcpList: React.FC<{
  selectedIcpId?: string

  className: string
}> = ({ selectedIcpId, className }) => {
  const { workspaceId } = useParams()
  const icpsQuery = useIcps()

  const { deleteIcp } = useIcpActions()
  const handleDeleteIcp = (icp: IcpMinimal) => {
    deleteIcp(icp.id)
  }

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true)
  }
  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false)
  }

  return (
    <RenderQuery query={icpsQuery} className={cx('min-h-[200px]', className)}>
      {(icps) => {
        if (!icps.length) {
          return (
            <div
              className={cx('flex min-h-[120px] flex-col items-center justify-center', className)}
            >
              <Modal
                className="flex max-w-md flex-col items-center"
                isOpen={isCreateModalOpen}
                onClose={handleCloseCreateModal}
              >
                <CreateIcpForm />
              </Modal>

              <p className="text-secondary">Your ICPs will appear here</p>
              <Button className="mt-2" size="small" onClick={handleOpenCreateModal}>
                Create ICP
              </Button>
            </div>
          )
        }

        return (
          <ul className={cx('flex flex-col gap-2', className)}>
            {icps.map((icp) => {
              return (
                <li
                  key={icp.id}
                  className="group relative flex flex-row items-center gap-2 rounded-2xl p-2 hover:bg-disabled-surface"
                >
                  <Link
                    to={`/${workspaceId}/icp/${icp.id}`}
                    className={cx(
                      'sidebar-int flex min-h-8 w-full flex-row items-center gap-2 text-left',
                      {
                        'text-primary': icp.id === selectedIcpId,
                      },
                    )}
                  >
                    <div className="flex flex-col justify-center overflow-hidden">
                      <p className="line-clamp-2">{icp.title}</p>
                    </div>
                  </Link>

                  <CancellableActionButton
                    className="text-secondary opacity-0 transition-all hover:text-danger group-hover:opacity-100"
                    color="danger"
                    onAction={() => handleDeleteIcp(icp)}
                  >
                    <IconTrash />
                  </CancellableActionButton>
                </li>
              )
            })}
          </ul>
        )
      }}
    </RenderQuery>
  )
}

export default SidebarIcpList
