import { Modal } from '@onsaui'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import AnalyticsService from '@/services/analytics'

import ConnectionStatus from './ConnectionStatus'

const LinkedInConnectedModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const isShowParam = searchParams.get('showLinkedInConnectionModal')
  useEffect(() => {
    if (isShowParam) {
      setSearchParams((params) => {
        params.delete('showLinkedInConnectionModal')
        return params
      })

      setIsOpen(true)

      AnalyticsService.trackEvent('LinkedInConnectedModal.Show')
    }
  }, [isShowParam, setSearchParams])

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      className="flex min-h-[200px] w-full max-w-md flex-col items-center text-center"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ConnectionStatus onClose={handleClose} />
    </Modal>
  )
}

export default LinkedInConnectedModal
