import { Button, Input, Loader, notify, TextArea } from '@onsaui'
import cx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { taskApi } from '@/modules/task/api'
import { workspaceActions } from '@/modules/workspace'
import useWorkspace from '@/modules/workspace/hooks/useWorkspace'
import AnalyticsService from '@/services/analytics'

import AutofillStatus from './AutofillStatus'

const autoHeight = {
  enabled: true,
  minLines: 3,
  maxLines: 10,
}

const MAX_LENGTH = 4096

const SystemPromptSettingsForm: React.FC<{ onCancel?: () => void; className?: string }> = ({
  onCancel,
  className,
}) => {
  const { workspaceId } = useParams()
  const { data: workspace, isPending, isError } = useWorkspace(workspaceId!)

  const [autofillTaskId, setAutofillTaskId] = useState<string | null>(
    localStorage.getItem('profle_autofill_task_id') ?? null,
  )
  const handleAutofill = async (e: React.FormEvent) => {
    e.preventDefault()

    AnalyticsService.trackEvent('SystemPromptSettingsForm.BtnAutofill.Click')

    const formData = new FormData(e.target as HTMLFormElement)
    const profileUrl = (formData.get('profileUrl') as string) ?? ''

    if (!profileUrl) {
      notify({ message: 'Please enter a LinkedIn profile URL', variant: 'error' })
      return
    }

    try {
      const task = await taskApi.autofillPersonalInfo(profileUrl)
      AnalyticsService.trackEvent('SystemPromptSettingsForm.Autofill.Started', { taskId: task.id })

      localStorage.setItem('profle_autofill_task_id', task.id)
      setAutofillTaskId(task.id)
    } catch (e) {
      AnalyticsService.trackEvent('SystemPromptSettingsForm.Autofill.Error', {
        message: getErrorMessage(e),
      })
      notify({ message: getErrorMessage(e), variant: 'error' })

      throw e
    }
  }
  const handleAutofillFinished = () => {
    setAutofillTaskId(null)
    localStorage.removeItem('profle_autofill_task_id')

    workspaceActions.invalidateWorkspace(workspaceId!)
  }

  const [companyInfo, setCompanyInfo] = useState('')
  const [personalInfo, setPersonalInfo] = useState('')
  const handleCompanyInfoChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCompanyInfo(e.target.value)
  }
  const handlePersonalInfoChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPersonalInfo(e.target.value)
  }

  useEffect(() => {
    if (workspace) {
      setCompanyInfo(workspace.companyInfo)
      setPersonalInfo(workspace.personalInfo)
    }
  }, [workspace])

  const hasChanges =
    companyInfo !== workspace?.companyInfo || personalInfo !== workspace?.personalInfo

  const [isSaving, setIsSaving] = useState(false)
  const handleSave = async () => {
    if (companyInfo.length > MAX_LENGTH) {
      notify({ message: `Company info must not exceed ${MAX_LENGTH} characters`, variant: 'error' })
      return
    }
    if (personalInfo.length > MAX_LENGTH) {
      notify({
        message: `Personal info must not exceed ${MAX_LENGTH} characters`,
        variant: 'error',
      })
      return
    }

    setIsSaving(true)

    try {
      await workspaceActions.setWorkspaceUserInfo(companyInfo, personalInfo)

      notify({ message: 'Settings saved', variant: 'success' })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
    } finally {
      setIsSaving(false)
    }
  }

  let body
  if (autofillTaskId) {
    return (
      <AutofillStatus
        className="min-h-[500px]"
        taskId={autofillTaskId}
        onFinished={handleAutofillFinished}
      />
    )
  }

  if (isPending) {
    body = (
      <div className="flex h-[300px] items-center justify-center">
        <Loader />
      </div>
    )
  } else if (isError) {
    body = <p className="text-danger">Failed to load workspace data</p>
  } else {
    body = (
      <>
        <div className="overflow-y-auto">
          <div className="mb-6">
            <form onSubmit={handleAutofill} className="mb-6">
              <p className="mb-2">Auto-fill info via your LinkedIn</p>

              <div className="flex flex-row gap-2">
                <Input
                  className="flex-grow"
                  type="text"
                  id="profileUrl"
                  name="profileUrl"
                  placeholder="https://www.linkedin.com/in/your-profile"
                />

                <Button type="submit" size="medium">
                  Auto-fill
                </Button>
              </div>
            </form>

            <p className="mb-2">Company info</p>
            <TextArea
              id="companyInfo"
              placeholder="We specialize in creating innovative software solutions tailored to meet the unique needs of businesses across various industries."
              autoHeight={autoHeight}
              value={companyInfo}
              onChange={handleCompanyInfoChange}
              isDisabled={isSaving}
              isError={companyInfo.length > MAX_LENGTH}
            />
            <p
              className={cx(
                'mt-1 text-sm',
                companyInfo.length > MAX_LENGTH ? 'text-danger' : 'text-secondary',
              )}
            >
              {companyInfo.length}/{MAX_LENGTH}
            </p>
          </div>

          <div className="mb-6">
            <p className="mb-2">Personal info</p>
            <TextArea
              id="personalInfo"
              placeholder="My expertise lies in understanding complex software solutions and translating their value into clear, compelling benefits for our clients."
              autoHeight={autoHeight}
              value={personalInfo}
              onChange={handlePersonalInfoChange}
              isDisabled={isSaving}
              isError={personalInfo.length > MAX_LENGTH}
            />
            <p
              className={cx(
                'mt-1 text-sm',
                personalInfo.length > MAX_LENGTH ? 'text-danger' : 'text-secondary',
              )}
            >
              {personalInfo.length}/{MAX_LENGTH}
            </p>
          </div>
        </div>
        <div className="mt-4 flex flex-row justify-between gap-2">
          <Button type="button" className="w-full" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="button"
            className="w-full"
            isDisabled={isSaving || !hasChanges}
            isLoading={isSaving}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className={className}>
      <div className="mb-6">
        <h2 className="mb-3 font-bold">Customize Prompt</h2>
        <p>
          What would you like Onsa to know about you and your company to provide better responses?
        </p>
      </div>

      {body}
    </div>
  )
}

export default SystemPromptSettingsForm
