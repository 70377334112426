import { Button, IfScreen, InfoTooltip, notify, TextArea } from '@onsaui'
import { IconInfoSm } from '@onsaui/icons'
import cx from 'clsx'
import { useEffect, useState } from 'react'

import { openIcpLeadDeletingModal } from '@/modules/icp'
import useIcpLeadsActions from '@/modules/icp/hooks/useIcpLeadsActions'
import { IcpLead } from '@/modules/icp/model'
import AnalyticsService from '@/services/analytics'
import NamedAvatar from '@/shared/component/NamedAvatar/NamedAvatar'

const IcpLeadItem: React.FC<{
  icpId: string
  icpLead: IcpLead
  className?: string
}> = ({ icpId, icpLead, className }) => {
  const { updateIcpLead } = useIcpLeadsActions()

  const [score, setScore] = useState(icpLead.score)
  const [comment, setComment] = useState(icpLead.comment ?? '')

  useEffect(() => {
    setScore(icpLead.score)
    setComment(icpLead.comment ?? '')
  }, [icpLead.score, icpLead.comment])

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  const handleDelete = async () => {
    openIcpLeadDeletingModal(icpId, icpLead.id)
  }

  const handleScore = async (score: number) => {
    AnalyticsService.trackEvent('IcpLeadItem.BtnSetScore.Click', {
      icpId,
      leadId: icpLead.id,
      score,
    })

    setScore(score)
    await updateIcpLead(icpId, icpLead.id, { score })
  }

  const handleSave = async () => {
    if (comment === (icpLead.comment ?? '')) {
      return
    }

    AnalyticsService.trackEvent('IcpLeadItem.CommentSave', {
      icpId,
      leadId: icpLead.id,
      comment,
    })

    const updatedLead = await updateIcpLead(icpId, icpLead.id, { comment })
    setComment(updatedLead.comment ?? '')
    notify({ message: 'Saved', variant: 'success' })
  }

  return (
    <div
      key={icpLead.id}
      className={cx(
        'flex flex-row items-start rounded-xl border border-disabled p-4 mobile:flex-col mobile:items-start',
        className,
      )}
    >
      <NamedAvatar name={icpLead.fullName} className="h-10 w-10 shrink-0 self-start rounded-full" />

      <div className="mx-4 w-full max-w-[300px] mobile:mx-0 mobile:my-4 mobile:max-w-max">
        <a className="text-primary" href={icpLead.linkedInUrl} target="_blank" rel="noreferrer">
          {icpLead.fullName}
        </a>

        <div className="mt-3 text-sm">
          <p className="text-secondary">Current role</p>
          <p>{icpLead.position}</p>
        </div>
        <div className="mt-3 text-sm">
          <p className="text-secondary">Company</p>
          <a
            className="block text-primary"
            href={icpLead.companyLinkedInUrl}
            target="_blank"
            rel="noreferrer"
          >
            {icpLead.companyName}
          </a>
        </div>
      </div>

      <div className="flex-grow" />

      <div className="flex w-full max-w-[360px] flex-col">
        <IfScreen mobile={<p className="mb-1">Set Score</p>} />
        <div className="flex flex-row items-center">
          <IfScreen
            desktop={
              <InfoTooltip
                content={
                  <div className="w-[400px] rounded-2xl bg-level1 p-5 text-sm shadow-card">
                    <p className="mb-2 font-semibold">Why this lead is in the list?</p>

                    <p className="mb-2">{icpLead.llmScoreSummary}</p>

                    <p className="text-disabled">
                      Feel free to change the score and add context, We&apos;ll consider all info
                      when searching for prospects.
                    </p>
                  </div>
                }
              >
                <div className="flex flex-grow cursor-help flex-row items-center">
                  <p className="mobile:text-sm">Set Score</p>
                  <IconInfoSm className="ml-1 shrink-0 text-disabled" />
                </div>
              </InfoTooltip>
            }
          />

          <div className="flex flex-row gap-2">
            {Array.from({ length: 5 }).map((_, index) => {
              const btnScore = index + 1
              const isCurrent = btnScore === score
              return (
                <Button
                  key={btnScore}
                  className={cx('w-10 min-w-10 max-w-10 !px-2', {
                    'border border-transparent': isCurrent,
                    '!border-default !text-secondary': !isCurrent,
                  })}
                  variant={isCurrent ? 'primary' : 'outlined'}
                  size="medium"
                  onClick={!isCurrent ? () => handleScore(btnScore) : undefined}
                >
                  {btnScore}
                </Button>
              )
            })}
          </div>
        </div>
        <TextArea
          className="mt-2"
          autoHeight={{ enabled: true, minLines: 3, maxLines: 5 }}
          placeholder="Why this lead is good or bad?"
          value={comment}
          onChange={handleCommentChange}
          onBlur={handleSave}
        />
      </div>
    </div>
  )
}

export default IcpLeadItem
