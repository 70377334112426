import cx from 'clsx'

import useIcpStatistics from '@/modules/icp/hooks/useIcpStatistics'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import StatSlot from './StatSlot'

const IcpStatisticsWidget: React.FC<{ icpId: string; className?: string }> = ({
  icpId,
  className,
}) => {
  const statisticsQuery = useIcpStatistics(icpId)

  return (
    <RenderQuery
      query={statisticsQuery}
      renderPending={null}
      renderError={(error) => {
        return (
          <div className={cx('flex flex-row gap-5 rounded-[20px] bg-level1 p-5', className)}>
            <p className="text-danger">{getErrorMessage(error)}</p>
          </div>
        )
      }}
    >
      {(statistics) => {
        if (statistics.outreached === 0) {
          return null
        }

        return (
          <div className={cx('rounded-[20px] bg-level1 p-5', className)}>
            <div className="flex snap-x snap-mandatory flex-row gap-5 overflow-auto">
              <StatSlot
                className="flex-1 snap-start"
                value={statistics.outreached}
                description="Prospects Contacted"
              />
              <StatSlot
                className="flex-1 snap-start"
                value={statistics.connected}
                description="Connections Accepted"
              />
              <StatSlot
                className="flex-1 snap-start"
                value={statistics.replied}
                description="Prospects Responded"
              />
              <StatSlot
                className="flex-1 snap-start"
                value={`${Math.round(statistics.successRate * 10) / 10}%`}
                description="Response Rate"
              />
            </div>
          </div>
        )
      }}
    </RenderQuery>
  )
}

export default IcpStatisticsWidget
