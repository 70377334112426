const selectValueBasedOnStringHash = (str: string, items: string[]): string => {
  function hashCode(str: string) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      // eslint-disable-next-line no-bitwise
      hash = (hash << 5) - hash + char
      // eslint-disable-next-line no-bitwise
      hash &= hash
    }
    return hash
  }

  const hash = hashCode(str)
  const index = Math.abs(hash) % items.length

  return items[index]
}

export default selectValueBasedOnStringHash
