import { taskApi } from '@/modules/task/api'
import { AnyTask, TaskAccess, TaskInfo } from '@/modules/task/model'
import { QueryService } from '@/services/query'

export const deleteTask = async (taskId: string) => {
  await taskApi.deleteTask(taskId)

  QueryService.getClient().setQueriesData(
    { queryKey: ['tasks'] },
    (oldData: TaskInfo[] | undefined) => {
      if (!oldData) {
        return oldData
      }

      return oldData.filter((t) => t.id !== taskId)
    },
  )
}

export const invalidateTask = (id: string) => {
  QueryService.getClient().invalidateQueries({ queryKey: ['task', id] })
  QueryService.getClient().invalidateQueries({ queryKey: ['anon-task', id] })
}

export const invalidateTasks = () => {
  QueryService.getClient().invalidateQueries({ queryKey: ['tasks'] })
}

export const updateTask = (taskId: string, task: Partial<AnyTask>) => {
  const originalTask = QueryService.getClient().getQueryData<AnyTask>(['task', taskId])
  QueryService.getClient().setQueriesData(
    { queryKey: ['task', taskId] },
    (oldData: AnyTask | undefined) => {
      if (!oldData) {
        return oldData
      }

      return {
        ...oldData,
        ...task,
      } as AnyTask
    },
  )

  return {
    cancel: () => {
      QueryService.getClient().setQueriesData({ queryKey: ['task', taskId] }, originalTask)
    },
  }
}

export const updateTaskAccess = async (taskId: string, access: TaskAccess) => {
  const { cancel } = updateTask(taskId, { access })

  try {
    const task = await taskApi.updateTaskAccess(taskId, {
      accessType: access.accessType,
      emails: access.invites,
    })

    QueryService.getClient().setQueriesData({ queryKey: ['task', taskId] }, task)

    return task
  } catch (error) {
    cancel()
    throw error
  }
}

export const addTaskToWorkspace = async (taskPublicId: string) => {
  const task = await taskApi.addTaskToWorkspace(taskPublicId)
  QueryService.getClient().setQueriesData({ queryKey: ['task', task.id] }, task)
  invalidateTasks()

  return task
}

export const regenerateTask = async (taskId: string) => {
  const task = await taskApi.regenerateTask(taskId)
  invalidateTasks()

  return task
}

export const renameTask = async (taskId: string, title: string) => {
  const task = await taskApi.renameTask(taskId, title)
  QueryService.getClient().setQueriesData({ queryKey: ['task', task.id] }, task)
  invalidateTasks()

  return task
}

export const prefetchTasks = () => {
  QueryService.getClient().prefetchQuery({ queryKey: ['tasks'], queryFn: taskApi.getTasks })
}
