import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import EnsureProcessedIcp from '@/modules/icp/components/EnsureProcessedIcp/EnsureProcessedIcp'
import IcpLoader from '@/modules/icp/components/IcpLoader/IcpLoader'
import IcpReferenceLeadsWidget from '@/modules/icp/components/IcpReferenceLeadsWidget/IcpReferenceLeadsWidget'
import IcpStageWidget from '@/modules/icp/components/IcpStageWidget/IcpStageWidget'
import { IcpStage } from '@/modules/icp/model'
import AnalyticsService from '@/services/analytics'

const IcpLeadsPage: React.FC = () => {
  useEffect(() => {
    AnalyticsService.trackEvent('IcpReferenceLeadsPage.View')
  }, [])

  const { icpId } = useParams()

  return (
    <DefaultPageLayout>
      <IcpStageWidget icpId={icpId!} currentStage={IcpStage.ReferenceLeads} className="mb-5" />
      <EnsureProcessedIcp icpId={icpId!} loader={<IcpLoader stage="reference-leads" />}>
        {(icp) => {
          return <IcpReferenceLeadsWidget className="flex-grow" icpId={icpId!} />
        }}
      </EnsureProcessedIcp>
    </DefaultPageLayout>
  )
}

export default IcpLeadsPage
