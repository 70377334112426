import { GenericTask, TaskType } from '@/modules/task/model'

export interface PersonSummary {
  name: string
  linkedin_url: string
  linkedin_avatar_url?: string
  sections: {
    id: string | null
    title: string
    items: string[]
  }[]
}

export interface CompanySummarySection {
  id: string | null
  title: string
  items: {
    id: string | null
    title: string
    content: string
  }[]
}

export interface OpenJobsSection {
  title: string
  groups: {
    title: string | null
    items: {
      title: string
      content: string
    }[]
  }[]
}

export interface CompanySummary {
  name?: string
  sections: CompanySummarySection[]
  open_jobs_section?: OpenJobsSection
}

export interface DiscussionTopics {
  starters?: {
    question: string
    explanation: string
  }[]

  topics: {
    post_title: string
    post_url: string
    conversation_starter: string
    explanation?: string
  }[]
}

export interface MemoSuggestedProfile {
  linkedinProfileUrl: string
  location: string
  name: string
  summary: string
}

export interface PersonMemoTaskData {
  personSummary: PersonSummary
  companySummary?: CompanySummary
  discussionTopics?: DiscussionTopics
  peopleAlsoViewed?: MemoSuggestedProfile[]
}

export type Task = GenericTask<TaskType.PersonMemo, PersonMemoTaskData>

export interface PersonMemoTaskDataExtended {
  personSummary: PersonSummary
  companySummary?: CompanySummary
  discussionTopics?: DiscussionTopics
  peopleAlsoViewed?: MemoSuggestedProfile[]

  currentRole?: string
  descriptionItems?: string[]

  educationItems?: string[]
  experienceItems?: string[]

  accomplishmentsItems?: string[]
}

export enum PersonSectionId {
  CurrentRole = 'section-current-role',
  Description = 'section-description',
  Education = 'section-education',
  Experience = 'section-experience',
  NotableAccomplishments = 'section-notable-accomplishments',
  ConnectionPoints = 'section-connection-points',
}

export enum CompanySectionId {
  Overview = 'section-overview',
  FinancialData = 'section-financial-data',
  ConnectionPoints = 'section-connection-points',
}

export enum CompanySectionItemId {
  CompanyDescription = 'item-company-description',
  Industry = 'item-industry',
  NumberOfEmployees = 'item-number-of-employees',
  YearFounded = 'item-year-founded',
  Specialties = 'item-specialties',
  LinkedInUrl = 'item-linkedin-profile-url',
  Revenue = 'item-revenue',
  Ebitda = 'item-ebitda',
  Capitalization = 'item-market-capitalization',
  Funding = 'item-funding',
}
