import { IconArrowLeftSm } from '@onsaui/icons'
import { useEffect } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import EnsureProcessedIcp from '@/modules/icp/components/EnsureProcessedIcp/EnsureProcessedIcp'
import IcpBody from '@/modules/icp/components/IcpContextWidget/IcpBody'
import IcpLeadsPreviewWidget from '@/modules/icp/components/IcpLeadsPreviewWidget/IcpLeadsPreviewWidget'
import OutreachTemplatesPreviewWidget from '@/modules/icp/components/OutreachTemplatesPreviewWidget/OutreachTemplatesPreviewWidget'
import AnalyticsService from '@/services/analytics'

const IcpViewPage: React.FC = () => {
  useEffect(() => {
    AnalyticsService.trackEvent('IcpViewPage.View')
  }, [])

  const { icpId } = useParams()
  const [searchParams] = useSearchParams()
  const isBackToCampaign = searchParams.get('backToCampaign')

  return (
    <EnsureProcessedIcp icpId={icpId!}>
      {(icp) => {
        return (
          <DefaultPageLayout>
            {isBackToCampaign ? (
              <Link to="../campaign">
                <button
                  type="button"
                  className="mb-5 inline-flex h-10 flex-row items-center justify-center self-start transition-all hover:text-primary"
                >
                  <IconArrowLeftSm className="mr-1" />
                  <span>Back to Campaign</span>
                </button>
              </Link>
            ) : null}

            <IcpBody icp={icp} className="mb-8" isViewOnly />

            <IcpLeadsPreviewWidget icpId={icpId!} className="min-h-[400px]" />

            <OutreachTemplatesPreviewWidget icpId={icpId!} className="mt-8 min-h-[400px]" />
          </DefaultPageLayout>
        )
      }}
    </EnsureProcessedIcp>
  )
}

export default IcpViewPage
