import axios from 'axios'

export const uploadFileToBucket = async (
  signedUrl: string,
  file: File,
  onProgress?: (progress: number) => void, // progress = 0..1
) => {
  const response = await axios.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent) => {
      if (!progressEvent.total) {
        return
      }

      const progress = progressEvent.loaded / progressEvent.total
      onProgress?.(progress)
    },
  })

  return response.data
}
