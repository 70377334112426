import { notify } from '@onsaui'
import { useState } from 'react'

import { icpLeadsActions } from '@/modules/icp'
import { icpLeadsApi } from '@/modules/icp/api'
import { IcpLead } from '@/modules/icp/model'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import AnalyticsService from '@/services/analytics'

const useIcpLeadsActions = () => {
  const [isGenerating, setIsGenerating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isExporting, setIsExporting] = useState(false)

  const generateIcpReferenceLeads = async (icpId: string) => {
    AnalyticsService.trackEvent('IcpLeadsActions.GenerateReferenceLeads.Start', { icpId })
    setIsGenerating(true)

    try {
      await icpLeadsActions.generateIcpReferenceLeads(icpId)
      AnalyticsService.trackEvent('IcpLeadsActions.GenerateReferenceLeads.Success', { icpId })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('IcpLeadsActions.GenerateReferenceLeads.Error', {
        icpId,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsGenerating(false)
    }
  }

  const generateIcpCampaignLeads = async (icpId: string) => {
    AnalyticsService.trackEvent('IcpLeadsActions.GenerateCampaignLeads.Start', { icpId })
    setIsGenerating(true)

    try {
      await icpLeadsActions.generateIcpCampaignLeads(icpId)
      AnalyticsService.trackEvent('IcpLeadsActions.GenerateCampaignLeads.Success', { icpId })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('IcpLeadsActions.GenerateCampaignLeads.Error', {
        icpId,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsGenerating(false)
    }
  }

  const updateIcpLead = async (icpId: string, leadId: string, changes: Partial<IcpLead>) => {
    AnalyticsService.trackEvent('IcpLeadsActions.UpdateLead.Start', { icpId, leadId })
    setIsUpdating(true)

    try {
      const lead = await icpLeadsActions.updateIcpLead(icpId, leadId, changes)
      AnalyticsService.trackEvent('IcpLeadsActions.UpdateLead.Success', { icpId, leadId })
      return lead
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('IcpLeadsActions.UpdateLead.Error', {
        icpId,
        leadId,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsUpdating(false)
    }
  }

  const deleteIcpLead = async (icpId: string, leadId: string, comment?: string) => {
    AnalyticsService.trackEvent('IcpLeadsActions.DeleteLead.Start', { icpId, leadId })
    setIsDeleting(true)

    try {
      await icpLeadsActions.deleteIcpLead(icpId, leadId, comment)
      AnalyticsService.trackEvent('IcpLeadsActions.DeleteLead.Success', { icpId, leadId })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('IcpLeadsActions.DeleteLead.Error', {
        icpId,
        leadId,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsDeleting(false)
    }
  }

  const createIcpLeadMemo = async (icpId: string, leadId: string) => {
    AnalyticsService.trackEvent('IcpLeadsActions.CreateLeadMemo.Start', { icpId, leadId })
    try {
      const memo = await icpLeadsApi.createIcpLeadMemo(icpId, leadId)
      AnalyticsService.trackEvent('IcpLeadsActions.CreateLeadMemo.Success', { icpId, leadId })

      return memo
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('IcpLeadsActions.CreateLeadMemo.Error', {
        icpId,
        leadId,
        message: getErrorMessage(e),
      })
      throw e
    }
  }

  const exportCampaignLeadsAsCsv = async (icpId: string, name?: string) => {
    AnalyticsService.trackEvent('IcpLeadsActions.ExportCampaignLeadsCsv.Start', { icpId })
    setIsExporting(true)
    try {
      const result = await icpLeadsActions.exportCampaignLeadsAsCsv(icpId, name)
      AnalyticsService.trackEvent('IcpLeadsActions.ExportCampaignLeadsCsv.Success', { icpId })

      return result
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('IcpLeadsActions.ExportCampaignLeadsCsv.Error', {
        icpId,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsExporting(false)
    }
  }

  return {
    generateIcpReferenceLeads,
    generateIcpCampaignLeads,
    isGenerating,
    deleteIcpLead,
    isDeleting,
    updateIcpLead,
    isUpdating,
    createIcpLeadMemo,
    exportCampaignLeadsAsCsv,
    isExporting,
  }
}

export default useIcpLeadsActions
