import { IcpLead } from '@/modules/icp/model'
import { ApiService } from '@/services/api'

import { icpLeadAdapter, icpLeadsWithStatusAdapter } from './icpLeadsApiAdapters'
import { IcpLeadResponse, IcpLeadsWithStatusResponse } from './icpLeadsApiInterfaces'

export const generateIcpLeads = async (icpId: string) => {
  const generateBody = { count: 10 }
  await ApiService.workspaceInstance.post(`/icps/${icpId}/leads`, generateBody)
}

export const getIcpLeads = async (icpId: string) => {
  const response = await ApiService.workspaceInstance.get<IcpLeadsWithStatusResponse>(
    `/icps/${icpId}/leads`,
  )

  return icpLeadsWithStatusAdapter(response.data)
}

export const generateIcpReferenceLeads = async (icpId: string) => {
  const generateBody = { count: 10, isReference: true }
  await ApiService.workspaceInstance.post(`/icps/${icpId}/leads`, generateBody)
}

export const getIcpReferenceLeads = async (icpId: string) => {
  const response = await ApiService.workspaceInstance.get<IcpLeadsWithStatusResponse>(
    `/icps/${icpId}/leads/reference`,
  )

  return icpLeadsWithStatusAdapter(response.data)
}

export const updateIcpLead = async (icpId: string, leadId: string, changes: Partial<IcpLead>) => {
  const response = await ApiService.workspaceInstance.patch<IcpLeadResponse>(
    `/icps/${icpId}/leads/${leadId}`,
    changes,
  )

  return icpLeadAdapter(response.data)
}

export const createIcpLeadMemo = async (icpId: string, leadId: string) => {
  const response = await ApiService.workspaceInstance.post<{ taskId: string }>(
    `/icps/${icpId}/leads/${leadId}/memo`,
  )

  return response.data.taskId
}

export const exportLeadsAsCsv = async (icpId: string) => {
  const response = await ApiService.workspaceInstance.get(`/icps/${icpId}/leads/export`)

  return response
}
