import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: ReturnType<typeof setTimeout>

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

const useScrollRestoration = () => {
  const location = useLocation()
  const mainElRef = useRef<HTMLDivElement | null>(
    (document.getElementsByTagName('main')[0] as HTMLDivElement) ?? null,
  )

  useEffect(() => {
    if (!mainElRef.current) {
      return
    }
    const mainEl = mainElRef.current

    const savedScrollPosition = sessionStorage.getItem(location.pathname) || '0'
    mainEl.scrollTo(0, parseInt(savedScrollPosition, 10))

    const handleScroll = () => {
      sessionStorage.setItem(location.pathname, `${mainEl.scrollTop}`)
    }
    const debouncedHandleScroll = debounce(handleScroll, 200)

    mainEl.addEventListener('scroll', debouncedHandleScroll)

    return () => {
      mainEl.removeEventListener('scroll', debouncedHandleScroll)
    }
  }, [location])
}

export default useScrollRestoration
