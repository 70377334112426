import cx from 'clsx'

const DefaultPageLayout: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className={cx('mx-auto flex w-full max-w-5xl flex-grow flex-col px-6 pb-8', className)}>
      {children}
    </div>
  )
}

export default DefaultPageLayout
