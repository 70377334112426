import { Button, Modal } from '@onsaui'
import cx from 'clsx'
import { useState } from 'react'

import { closeConfirmModal, useConfirmModalStore } from './store'

const ConfirmModal: React.FC = () => {
  const { isOpen, config } = useConfirmModalStore()
  const { body = '', confirmLabel = 'Confirm', action, footerAlign = 'row' } = config || {}

  const [isProcessing, setIsProcessing] = useState(false)

  const handleClose = () => {
    closeConfirmModal(false)
  }

  const handleConfirm = async () => {
    if (action) {
      setIsProcessing(true)
      try {
        await action()
      } finally {
        setIsProcessing(false)
      }
    }

    closeConfirmModal(true)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="w-full max-w-[380px]">
      {body}

      <div
        className={cx('mt-6', {
          'flex flex-row-reverse items-center gap-4': footerAlign === 'row',
          'flex flex-col gap-2': footerAlign === 'col',
        })}
      >
        <Button onClick={handleConfirm} isDisabled={isProcessing} isLoading={isProcessing}>
          {confirmLabel}
        </Button>
        <Button variant="ghost" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  )
}
export default ConfirmModal
