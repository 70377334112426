import { Button } from '@onsaui'
import clsx from 'clsx'
import { useNavigate, useParams } from 'react-router-dom'

import { openUpgradeToProModal } from '@/modules/billing/store/billingStore'
import useCreatePersonMemoTask from '@/modules/task/hooks/useCreatePersonMemoTask'
import { PersonMemo } from '@/modules/task/model'

const SimilarProfile: React.FC<{
  profile: PersonMemo.MemoSuggestedProfile
  className?: string
}> = ({ profile, className }) => {
  const { workspaceId } = useParams()
  const navigate = useNavigate()

  const { createTask, isCreating } = useCreatePersonMemoTask(openUpgradeToProModal)

  const handleViewMemo = async () => {
    const task = await createTask(profile.linkedinProfileUrl, { isAnon: false, useCached: true })
    navigate(`/${workspaceId}/t/${task.id}`)

    // Reset scroll after navigation, have to fix with <ScrollRestoration /> from ReactRouter
    setTimeout(() => {
      document.getElementsByTagName('main')[0]?.scrollTo(0, 0)
    })
  }

  return (
    <li
      className={clsx(
        'flex flex-row items-center gap-3 rounded-2xl border border-default p-5',
        className,
      )}
    >
      {/* <CircleIconPerson className="mt-1 shrink-0 text-black/80" /> */}
      <div className="flex-grow">
        <a
          className="shrink-0 text-base font-normal text-primary"
          href={profile.linkedinProfileUrl}
          target="_blank"
          rel="noreferrer"
        >
          {profile.name}
        </a>
        <p>{profile.summary}</p>
      </div>

      <Button
        className="w-[130px]"
        size="medium"
        variant="outlined"
        onClick={handleViewMemo}
        isLoading={isCreating}
        isDisabled={isCreating}
      >
        View Memo
      </Button>
    </li>
  )
}

export default SimilarProfile
