import cx from 'clsx'
import { useParams } from 'react-router-dom'

import useAuth from '@/modules/auth/hooks/useAuth'
import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import PersonalizeReportBanner from '@/modules/task/components/PersonalizeReportBanner/PersonalizeReportBanner'
import PersonMemoReportView from '@/modules/task/components/PersonMemoReportView/PersonMemoReportView'
import TaskFeedback from '@/modules/task/components/TaskFeedback/TaskFeedback'
import TaskHeader from '@/modules/task/components/TaskHeader/TaskHeader'
import { PersonMemo, TaskContext, TaskStatus } from '@/modules/task/model'

const PersonMemoPage: React.FC<{
  task: PersonMemo.Task
  taskContext?: TaskContext
}> = ({ task, taskContext }) => {
  const { workspaceId } = useParams()
  if (task.status !== TaskStatus.Completed) {
    throw new Error('Task is not completed')
  }

  const { isAuth } = useAuth()

  return (
    <DefaultPageLayout>
      <TaskHeader task={task} taskContext={taskContext} className="mb-8" />

      {workspaceId && <PersonalizeReportBanner workspaceId={workspaceId} className="mb-8" />}
      <div className="flex-grow" />
      <PersonMemoReportView data={task.responseData} isAnon={!isAuth} />
      {taskContext?.withFeedback ? (
        <section className="report-section mt-8 flex flex-col items-center justify-center">
          <TaskFeedback taskId={task.id} taskType={task.type} />
        </section>
      ) : null}
    </DefaultPageLayout>
  )
}

export default PersonMemoPage
