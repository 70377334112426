import { Icp, IcpMinimal } from '@/modules/icp/model'
import { ApiService } from '@/services/api'

import { icpAdapter, icpStatisticsAdapter } from './icpApiAdapters'
import { IcpResponse, IcpStatisticsResponse } from './icpApiInterfaces'

export const getIcps = async () => {
  const response = await ApiService.workspaceInstance.get<IcpMinimal[]>('/icps')

  return response.data
}

export const createIcp = async (companyWebsiteUrl: string) => {
  const response = await ApiService.workspaceInstance.post<IcpResponse>('/icps', {
    websiteUrl: companyWebsiteUrl,
  })

  return icpAdapter(response.data)
}

export const getIcp = async (id: string) => {
  const response = await ApiService.workspaceInstance.get<IcpResponse>(`/icps/${id}`)

  return icpAdapter(response.data)
}

export const updateIcp = async (id: string, icp: Partial<Icp>) => {
  const response = await ApiService.workspaceInstance.patch<IcpResponse>(`/icps/${id}`, icp)

  return icpAdapter(response.data)
}

export const deleteIcp = async (id: string) => {
  await ApiService.workspaceInstance.delete(`/icps/${id}`)
}

export const regenerateIcp = async (id: string) => {
  const response = await ApiService.workspaceInstance.post<IcpResponse>(`/icps/${id}/regenerate`)

  return icpAdapter(response.data)
}

export const createSignedUrl = async (
  id: string,
  fileConfig: { fileName: string; fileSize: number; mimeType: string },
) => {
  const response = await ApiService.workspaceInstance.post<{ signedUrl: string }>(
    `/icps/${id}/signed-url`,
    fileConfig,
  )

  return response.data.signedUrl
}
export const addFile = async (
  id: string,
  fileConfig: { fileName: string; fileSize: number; mimeType: string },
) => {
  const response = await ApiService.workspaceInstance.post<IcpResponse>(
    `/icps/${id}/add-file`,
    fileConfig,
  )

  return icpAdapter(response.data)
}
export const deleteFile = async (id: string, fileName: string) => {
  const response = await ApiService.workspaceInstance.post<IcpResponse>(`/icps/${id}/delete-file`, {
    id: fileName,
  })

  return icpAdapter(response.data)
}

export const getIcpStatistics = async (icpId: string) => {
  // const responseMock: IcpStatisticsResponse = {
  //   leadsOutreached: 10,
  //   leadsConnected: 5,
  //   leadsReplied: 3,
  // }
  const response = await ApiService.workspaceInstance.get<IcpStatisticsResponse>(
    `/icps/${icpId}/dashboard`,
  )

  return icpStatisticsAdapter(response.data)
}
