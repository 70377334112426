import { Outlet, useParams } from 'react-router-dom'

import useIcp from '@/modules/icp/hooks/useIcp'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

const IcpWrapperPage: React.FC = () => {
  const { icpId } = useParams()
  const icpQuery = useIcp(icpId!)

  return (
    <RenderQuery query={icpQuery}>
      {() => {
        return <Outlet />
      }}
    </RenderQuery>
  )
}

export default IcpWrapperPage
