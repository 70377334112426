import { Button, Loader } from '@onsaui'
import { IconLinkedIn } from '@onsaui/icons'
import { useEffect, useState } from 'react'

import useUserMe from '@/modules/user/hooks/useUserMe'
import AnalyticsService from '@/services/analytics'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

const ConnectionStatus: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [isTimeout, setIsTimeout] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setIsTimeout(true)
    }, 10000)
  }, [])

  const userQuery = useUserMe(1500)

  const avatarUrl = userQuery.data?.linkedInConnection?.avatarUrl
  const avatar = avatarUrl ? (
    <img
      src={avatarUrl}
      alt={userQuery.data?.firstName}
      className="mb-4 h-24 w-24 shrink-0 rounded-full"
    />
  ) : (
    <IconLinkedIn className="mb-4 h-24 w-24 shrink-0" />
  )

  const linkedInConnection = userQuery.data?.linkedInConnection

  useEffect(() => {
    if (linkedInConnection) {
      AnalyticsService.trackEvent('LinkedInConnectedModal.ConnectionSuccess')
    }
  }, [linkedInConnection])
  useEffect(() => {
    if (isTimeout && (!linkedInConnection || !linkedInConnection.isActive)) {
      AnalyticsService.trackEvent('LinkedInConnectedModal.ConnectionFailed')
    }
  }, [linkedInConnection, isTimeout])

  return (
    <>
      {avatar}
      <RenderQuery query={userQuery} className="mb-4 mt-6">
        {({ linkedInConnection }) => {
          if (!linkedInConnection || !linkedInConnection.isActive) {
            if (isTimeout) {
              return (
                <p className="text-danger">
                  It seems your LinkedIn connection is inactive or disconnected. Please try again.
                </p>
              )
            }
            return <Loader className="my-4" />
          }

          return (
            <>
              <h2 className="mb-6 font-semibold">Account successfully connected</h2>

              <a
                className="text-primary"
                href={linkedInConnection.linkedInUrl}
                target="_blank"
                rel="noreferrer"
              >
                {linkedInConnection.linkedInUrl}
              </a>
            </>
          )
        }}
      </RenderQuery>
      <Button className="mt-10 w-full" onClick={onClose} isDisabled={userQuery.isPending}>
        Continue
      </Button>
    </>
  )
}

export default ConnectionStatus
