import { Navigate, useParams } from 'react-router-dom'

import useIcp from '@/modules/icp/hooks/useIcp'
import { IcpStage } from '@/modules/icp/model'

const IcpRootPage: React.FC = () => {
  const { icpId } = useParams()
  const { data: icp } = useIcp(icpId!)

  if (!icp) {
    return null
  }

  if (icp.stage === IcpStage.Finalized) {
    return <Navigate to="campaign" replace />
  }

  return <Navigate to="edit" replace />
}

export default IcpRootPage
