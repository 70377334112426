import { IconChevronDownSm } from '@onsaui/icons'
import cx from 'clsx'

const TasksCategory: React.FC<{
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  title: string
  children?: React.ReactNode
  isOpen?: boolean
  onToggle?: () => void
  className?: string
}> = ({ Icon, title, isOpen, onToggle, children, className }) => {
  return (
    <div className={cx('flex flex-col', className, { 'flex-1': isOpen })}>
      <button type="button" className="flex w-full flex-row items-center py-5" onClick={onToggle}>
        <div className="mr-2 rounded-full bg-black p-2">
          <Icon className="h-5 w-5 text-white" />
        </div>
        <p className="">{title}</p>
        <div className="flex-grow" />
        <IconChevronDownSm className={cx({ 'rotate-180': isOpen })} />
      </button>

      {isOpen ? children : null}
    </div>
  )
}

export default TasksCategory
