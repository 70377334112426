import { notify } from '@onsaui'
import { isAxiosError } from 'axios'
import { useState } from 'react'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { taskApi } from '@/modules/task/api'
import { TaskType } from '@/modules/task/model'
import AnalyticsService from '@/services/analytics'

import { taskActions } from '../actions'

const useCreatePersonMemoTask = (onLimitExeededError?: () => void) => {
  const [isCreating, setIsCreating] = useState(false)

  const createTask = async (
    personUrl: string,
    data: { context?: string; isAnon?: boolean; useCached?: boolean },
  ) => {
    const { isAnon } = data

    AnalyticsService.trackEvent('CreateTask.Start', { taskType: TaskType.PersonMemo })

    setIsCreating(true)
    try {
      const task = isAnon
        ? await taskApi.createAnonPersonMemo(personUrl, data)
        : await taskApi.createPersonMemo(personUrl, data)
      if (!isAnon) {
        taskActions.invalidateTasks()
      }

      AnalyticsService.trackEvent('CreateTask.Success', {
        taskType: task.type,
        taskId: task.id,
      })

      return task
    } catch (e) {
      let isHandled = false
      if (isAxiosError(e)) {
        if (e.response?.status === 402) {
          isHandled = true
          AnalyticsService.trackEvent('CreateTask.ErrorLimitExeeded', {
            taskType: TaskType.PersonMemo,
          })
          notify({ message: getErrorMessage(e), variant: 'error' })
          onLimitExeededError?.()
        }
        if (e.response?.status === 429) {
          isHandled = true
          notify({ message: getErrorMessage(e), variant: 'error' })
        }
      }

      if (!isHandled) {
        AnalyticsService.trackEvent('CreateTask.Error', {
          message: getErrorMessage(e),
          taskType: TaskType.PersonMemo,
        })

        notify({ message: getErrorMessage(e), variant: 'error' })
      }

      throw e
    } finally {
      setIsCreating(false)
    }
  }

  return { isCreating, createTask }
}

export default useCreatePersonMemoTask
