import { icpActions, icpLeadsActions } from '@/modules/icp'
import { outreachApi } from '@/modules/icp/api'
import { OutreachStageType, OutreachTemplate } from '@/modules/icp/model'
import { QueryService } from '@/services/query'

export const invalidateOutreachDetails = (icpId: string, leadId: string) => {
  QueryService.getClient().invalidateQueries({ queryKey: ['outreachDetails', icpId, leadId] })
}

export const createOutreachTemplate = async (
  icpId: string,
  title: string,
  message: string,
  position: number = 100,
) => {
  const template = await outreachApi.createOutreachTemplate(icpId, title, message, position)

  QueryService.getClient().setQueriesData(
    {
      queryKey: ['outreachTemplates', icpId],
    },
    (oldData?: OutreachTemplate[]) => {
      if (!oldData) {
        return oldData
      }

      return [...oldData, template]
    },
  )

  return template
}

export const updateOutreachTemplate = async (
  icpId: string,
  templateId: string,
  changes: Partial<OutreachTemplate>,
) => {
  const updatedTemplate = await outreachApi.updateOutreachTemplate(icpId, templateId, changes)

  QueryService.getClient().setQueriesData(
    {
      queryKey: ['outreachTemplates', icpId],
    },
    (oldData?: OutreachTemplate[]) => {
      if (!oldData) {
        return oldData
      }

      return oldData.map((template) => {
        if (template.id === templateId) {
          return updatedTemplate // replace the updated template
        }

        return template
      })
    },
  )

  return updatedTemplate
}

export const deleteOutreachTemplate = async (icpId: string, templateId: string) => {
  await outreachApi.deleteOutreachTemplate(icpId, templateId)

  QueryService.getClient().setQueriesData(
    {
      queryKey: ['outreachTemplates', icpId],
    },
    (oldData?: OutreachTemplate[]) => {
      if (!oldData) {
        return oldData
      }

      return oldData.filter((template) => template.id !== templateId)
    },
  )
}

export const startOutreachAll = async (icpId: string) => {
  const leads = await outreachApi.startOutreachAll(icpId)

  icpLeadsActions.setIcpCampaignLeads(icpId, (oldData) => {
    if (!oldData) {
      return
    }

    return leads
  })
}

export const startOutreachLead = async (icpId: string, leadId: string) => {
  const lead = await outreachApi.startOutreachLead(icpId, leadId)

  icpLeadsActions.setIcpLead(icpId, lead)

  invalidateOutreachDetails(icpId, leadId)
  icpActions.invalidateIcpStatistics(icpId)
}

export const stopOutreachLead = async (icpId: string, leadId: string) => {
  const lead = await outreachApi.stopOutreachLead(icpId, leadId)

  icpLeadsActions.setIcpLead(icpId, lead)

  invalidateOutreachDetails(icpId, leadId)
}

export const updateOutreachStageMessage = async (
  icpId: string,
  leadId: string,
  stageType: OutreachStageType,
  message: string,
) => {
  const details = await outreachApi.updateOutreachStageMessage(icpId, leadId, stageType, message)
  QueryService.getClient().setQueriesData({ queryKey: ['outreachDetails', icpId, leadId] }, details)

  return details
}
