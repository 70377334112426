import { useQuery } from '@tanstack/react-query'

import { outreachApi } from '@/modules/icp/api'

const useOutreachTemplates = (icpId: string) => {
  return useQuery({
    queryKey: ['outreachTemplates', icpId],
    queryFn: () => {
      return outreachApi.getOutreachTemplates(icpId)
    },
  })
}

export default useOutreachTemplates
