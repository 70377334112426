import {
  PersonMemoTaskData,
  PersonMemoTaskDataExtended,
  PersonSectionId,
  PersonSummary,
} from './personMemoInterfaces'

function legacyTitleToId(title: string): string | null {
  switch (title.toLowerCase()) {
    case 'Current role'.toLowerCase():
      return PersonSectionId.CurrentRole
    case 'Description'.toLowerCase():
      return PersonSectionId.Description
    case 'Education'.toLowerCase():
      return PersonSectionId.Education
    case 'Experience'.toLowerCase():
      return PersonSectionId.Experience
    case 'Notable accomplishments'.toLowerCase():
      return PersonSectionId.NotableAccomplishments
    default:
      return null
  }
}

export const transformPersonMemoToMarkdown = (data: PersonMemoTaskData): string => {
  let markdown = `# ${data.personSummary.name}\n\n`

  if (data.personSummary.linkedin_url) {
    markdown += `### [LinkedIn Profile](${data.personSummary.linkedin_url})\n\n`
  }

  if (data.personSummary.linkedin_avatar_url) {
    markdown += `<img src="${data.personSummary.linkedin_avatar_url}" alt="${data.personSummary.name}" width="200"/>\n\n`
  }

  if (data.personSummary.sections) {
    data.personSummary.sections?.forEach((section) => {
      markdown += `## ${section.title}\n\n`
      section.items?.forEach((item) => {
        markdown += `- ${item}\n`
      })
      markdown += '\n'
    })
  }

  if (data.companySummary) {
    if (data.companySummary.name) {
      markdown += `# Company: ${data.companySummary.name}\n\n`
    }
    data.companySummary.sections?.forEach((section) => {
      markdown += `## ${section.title}\n\n`
      section.items?.forEach((item) => {
        markdown += `### ${item.title}\n\n`
        markdown += `${item.content}\n\n`
      })
    })
    if (data.companySummary.open_jobs_section) {
      markdown += `## Open Jobs\n\n`
      data.companySummary.open_jobs_section.groups?.forEach((group) => {
        if (group.title) {
          markdown += `### ${group.title}\n\n`
        }
        group.items?.forEach((item) => {
          markdown += `#### ${item.title}\n\n`
          markdown += `${item.content}\n\n`
        })
      })
    }
  }

  if (data.discussionTopics) {
    if (data.discussionTopics.starters && data.discussionTopics.starters.length > 0) {
      markdown += `## Conversation Starters\n\n`
      data.discussionTopics.starters?.forEach((starter) => {
        markdown += `### ${starter.question}\n\n`
        markdown += `- ${starter.explanation}\n\n`
      })
    }

    markdown += `## Discussion Topics\n\n`
    data.discussionTopics.topics?.forEach((topic) => {
      markdown += `- [**${topic.post_title}**](${topic.post_url})\n`
      if (topic.explanation) {
        markdown += `  - **Explanation**: ${topic.explanation}\n`
      }
      markdown += `  - **Question:** ${topic.conversation_starter}\n`
      markdown += '\n'
    })
  }

  return markdown
}

export const extendPersonMemo = (data: PersonMemoTaskData) => {
  let currentRole: string | undefined
  let descriptionItems: string[] | undefined

  let educationItems: string[] | undefined
  let experienceItems: string[] | undefined

  let accomplishmentsItems: string[] | undefined

  const personSummary: PersonSummary = { ...data.personSummary, sections: [] }

  data.personSummary.sections.forEach((section) => {
    const targetId = section.id || legacyTitleToId(section.title)

    switch (targetId) {
      case PersonSectionId.CurrentRole:
        currentRole = section.items?.[0]
        break
      case PersonSectionId.Description:
        descriptionItems = section.items
        break
      case PersonSectionId.Education:
        educationItems = section.items
        break
      case PersonSectionId.Experience:
        experienceItems = section.items
        break
      case PersonSectionId.NotableAccomplishments:
        accomplishmentsItems = section.items
        break
      default:
        personSummary.sections.push(section)
        break
    }
  })

  const extendedData: PersonMemoTaskDataExtended = {
    personSummary,
    companySummary: data.companySummary,
    discussionTopics: data.discussionTopics,
    peopleAlsoViewed: data.peopleAlsoViewed,

    currentRole,
    descriptionItems,
    educationItems,
    experienceItems,
    accomplishmentsItems,
  }
  return extendedData
}
