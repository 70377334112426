/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CancellableActionButton, IfScreen, notify } from '@onsaui'
import { IconTrash } from '@onsaui/icons'
import cx from 'clsx'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'

import useIcpLeadsActions from '@/modules/icp/hooks/useIcpLeadsActions'
import { IcpLead, OutreachStageStatus } from '@/modules/icp/model'
import AnalyticsService from '@/services/analytics'

import OutreachStatusBadge from '../OutreachStatusBadge/OutreachStatusBadge'
import cm from './CampaignLeadItem.module.css'

const CampaignLeadItem: React.FC<{
  icpId: string
  lead: IcpLead
  className?: string
}> = ({ icpId, lead, className }) => {
  const { deleteIcpLead } = useIcpLeadsActions()

  const handleClick = () => {
    AnalyticsService.trackEvent('CampaignLeadItem.Click', {
      icpId,
      leadId: lead.id,
    })
  }

  const handleDeleteClick = () => {
    AnalyticsService.trackEvent('CampaignLeadItem.BtnDelete.Click', {
      icpId,
      leadId: lead.id,
    })
  }

  const handleDelete = useCallback(async () => {
    await deleteIcpLead(icpId, lead.id)
    notify({ message: 'Deleted', variant: 'success' })
  }, [deleteIcpLead, icpId, lead.id])

  const isOutreachRunning = Boolean(
    lead.outreachStage &&
      (lead.outreachStage.status === OutreachStageStatus.Pending ||
        lead.outreachStage.status === OutreachStageStatus.Scheduled),
  )

  return (
    <Link
      key={lead.id}
      to={`../outreach/${lead.id}?backToCampaign=true`}
      onClick={handleClick}
      preventScrollReset
    >
      <div
        className={cx(
          'flex flex-row items-center rounded-[20px] border border-disabled p-4 transition-all hover:border-primary mobile:flex-col mobile:items-start mobile:gap-2',
          cm.primaryHoverShadow,
          className,
        )}
      >
        <div className="flex min-w-[250px] flex-row items-center gap-2 mobile:w-full">
          <p>{lead.fullName}</p>
          <p className="rounded-md bg-disabled-surface px-2 py-1">
            <span className="font-bold">{lead.score}</span>/5
          </p>

          <div className="flex-grow" />

          <IfScreen
            mobile={
              <div
                className="-mr-2 flex flex-col items-center justify-center"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <CancellableActionButton
                  className="p-1"
                  color="danger"
                  onClick={handleDeleteClick}
                  onAction={handleDelete}
                >
                  <IconTrash />
                </CancellableActionButton>
              </div>
            }
          />
        </div>
        <div className="flex-grow text-sm">
          <div className="max-w-[270px]">
            <p>
              <span>Current Role: </span>
              {lead.position}
            </p>

            <p>
              <span>Company: </span>
              <span>{lead.companyName}</span>
            </p>
          </div>
        </div>
        {lead.outreachStage?.status ? (
          <OutreachStatusBadge
            className="desktop:ml-3"
            stage={lead.outreachStage.type}
            status={lead.outreachStage.status}
          />
        ) : null}

        <IfScreen
          desktop={
            <div
              className="-my-4 -mr-4 ml-5 flex flex-col items-center justify-center self-stretch border-l border-disabled"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <CancellableActionButton
                className="p-2"
                color="danger"
                onClick={handleDeleteClick}
                onAction={handleDelete}
              >
                <IconTrash />
              </CancellableActionButton>
            </div>
          }
        />
      </div>
    </Link>
  )
}

export default CampaignLeadItem
