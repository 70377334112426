import { Button, notify } from '@onsaui'
import { IconLinkedIn } from '@onsaui/icons'
import cx from 'clsx'
import { useState } from 'react'

import { openConfirmModal } from '@/modules/shared'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { openConnectLinkedInModal, userActions } from '@/modules/user'
import useUserMe from '@/modules/user/hooks/useUserMe'
import AnalyticsService from '@/services/analytics'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

const LinkedInConnectionWidget: React.FC<{ className?: string }> = ({ className }) => {
  const userQuery = useUserMe()

  const handleConnect = async () => {
    AnalyticsService.trackEvent('LinkedInConnectionWidget.BtnConnect.Click')
    openConnectLinkedInModal()
  }

  const [isReconnecting, setIsReconnecting] = useState(false)
  const handleReconnect = async () => {
    AnalyticsService.trackEvent('LinkedInConnectionWidget.BtnReconnect.Click')

    setIsReconnecting(true)
    try {
      await userActions.connectLinkedIn()
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsReconnecting(false)
    }
  }

  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const handleDisconnect = async () => {
    AnalyticsService.trackEvent('LinkedInConnectionWidget.BtnDisconnect.Click')

    const isConfirmed = await openConfirmModal({
      body: (
        <div className="flex flex-col items-center text-center">
          {userQuery.data?.linkedInConnection?.avatarUrl ? (
            <img
              src={userQuery.data?.linkedInConnection?.avatarUrl}
              alt={userQuery.data?.linkedInConnection?.firstName}
              className="mb-4 h-24 w-24 shrink-0 rounded-full"
            />
          ) : (
            <IconLinkedIn className="mb-4 shrink-0" />
          )}
          <h2 className="mb-4 font-semibold">Outreach process will be stopped</h2>
          <p className="mb-4">
            Are you sure you want to disconnect this account and stop the outreach?
          </p>
          <a
            className="text-primary"
            href={userQuery.data?.linkedInConnection?.linkedInUrl}
            target="_blank"
            rel="noreferrer"
          >
            {userQuery.data?.linkedInConnection?.linkedInUrl}
          </a>
        </div>
      ),
      footerAlign: 'col',
      confirmLabel: 'Disconnect',
      action: () => userActions.disconnectLinkedIn(),
    })

    if (isConfirmed) {
      notify({ message: 'LinkedIn account disconnected', variant: 'success' })
    }
  }

  return (
    <RenderQuery className={className} query={userQuery}>
      {({ linkedInConnection }) => {
        const avatarUrl = linkedInConnection?.avatarUrl
        const fullName = `${linkedInConnection?.firstName} ${linkedInConnection?.lastName}`

        const avatar = avatarUrl ? (
          <img src={avatarUrl} alt={fullName} className="h-6 w-6 shrink-0 rounded-full" />
        ) : (
          <IconLinkedIn className="shrink-0" />
        )

        return (
          <div className={cx('rounded-2xl bg-disabled-surface p-4', className)}>
            {!linkedInConnection ? (
              <div className="flex flex-row items-center">
                <p className="text-sm text-secondary">
                  Connect LinkedIn to outreach prospect leads
                </p>
                <Button size="small" variant="outlined" onClick={handleConnect}>
                  Connect
                </Button>
              </div>
            ) : null}

            {linkedInConnection && !linkedInConnection.isActive ? (
              <div>
                <p className="mb-2 text-sm text-secondary">Connected LinkedIn account</p>

                <div className="flex flex-row items-center gap-4">
                  <div className="inline-flex flex-grow flex-row items-center overflow-hidden">
                    {avatar}
                    <span className="ml-2 truncate">{fullName}</span>
                  </div>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleReconnect}
                    isDisabled={isReconnecting}
                    isLoading={isReconnecting}
                  >
                    Reconnect
                  </Button>
                </div>
              </div>
            ) : null}

            {linkedInConnection && linkedInConnection.isActive ? (
              <div>
                <p className="mb-2 text-sm text-secondary">Connected LinkedIn account</p>

                <div className="flex flex-row items-center gap-4">
                  <div className="inline-flex flex-grow flex-row items-center overflow-hidden">
                    {avatar}
                    <span className="ml-2 truncate">{fullName}</span>
                  </div>
                  <Button
                    size="small"
                    variant="ghost"
                    onClick={handleDisconnect}
                    isDisabled={isDisconnecting}
                    isLoading={isDisconnecting}
                  >
                    Disconnect
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        )
      }}
    </RenderQuery>
  )
}

export default LinkedInConnectionWidget
