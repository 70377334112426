import { TaskStatus } from '@/modules/task/model'

export enum IcpStage {
  SetContext = 'set-context',
  ReferenceLeads = 'reference-leads',
  MessageTemplates = 'message-templates',
  Confirmation = 'confirmation',
  Finalized = 'finalized',
}

export interface IcpFile {
  createdAt: string
  duration: number | null
  mimeType: string
  origin: string
  originName: string
  size: number
  type: string
}

export interface Icp {
  id: string
  title: string
  linkedInUrl: string | null
  context: string
  files: IcpFile[]
  body: string
  offerings: string[]
  status: TaskStatus
  stage: IcpStage
  changelog?: string
}

export interface IcpMinimal {
  id: string
  title: string
}

export interface IcpStatistics {
  outreached: number
  connected: number
  replied: number
  successRate: number
}
