import { Button, notify, TextArea } from '@onsaui'
import cx from 'clsx'
import { useEffect, useState } from 'react'

import useIcpLeadsActions from '@/modules/icp/hooks/useIcpLeadsActions'
import { IcpLead } from '@/modules/icp/model'

const DeletingForm: React.FC<{
  icpId: string
  lead: IcpLead
  onDeleted?: () => void
  onCancel?: () => void
  className?: string
}> = ({ icpId, lead, onDeleted, onCancel, className }) => {
  const [comment, setComment] = useState(lead.comment ?? '')
  useEffect(() => {
    setComment(lead.comment ?? '')
  }, [lead.comment])

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  const { deleteIcpLead, isDeleting } = useIcpLeadsActions()

  const handleDelete = async () => {
    await deleteIcpLead(icpId, lead.id, comment)

    notify({ message: 'Deleted', variant: 'success' })
    onDeleted?.()
  }

  return (
    <div className={cx('flex flex-col items-center', className)}>
      <h2 className="mb-6 text-center font-semibold">
        <span>Would you like to remove this lead from the references?</span>
      </h2>
      <a href={lead.linkedInUrl} target="_blank" rel="noreferrer" className="mb-6 text-primary">
        {lead.fullName}
      </a>
      <p className="mb-6 text-center">
        If you score a lead as 1, 2, or 3, we recommend removing them from references for a more
        efficient campaign. <span className="font-semibold">We&apos;ll still consider them</span>{' '}
        when searching for prospects.
      </p>

      <div className="mb-4 w-full">
        <p className="mb-1">Explain the reason</p>
        <TextArea
          autoHeight={{ enabled: true, minLines: 3, maxLines: 5 }}
          placeholder="Why this lead doesn't fit?"
          value={comment}
          onChange={handleCommentChange}
        />
      </div>

      <Button
        className="w-full"
        type="button"
        variant="primary"
        onClick={handleDelete}
        isLoading={isDeleting}
        isDisabled={isDeleting}
      >
        Remove This Lead
      </Button>
      <Button className="mt-2 w-full" type="button" variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  )
}

export default DeletingForm
