import { create } from 'zustand'

export interface UserStore {
  isConnectLinkedInModalOpen: boolean
}

export const useUserStore = create<UserStore>((set) => ({
  isConnectLinkedInModalOpen: false,
}))

export const openConnectLinkedInModal = () => {
  useUserStore.setState({ isConnectLinkedInModalOpen: true })
}
export const closeConnectLinkedInModal = () => {
  useUserStore.setState({ isConnectLinkedInModalOpen: false })
}

// @ts-expect-error - expose to the window
window.openConnectLinkedInModal = openConnectLinkedInModal
