import cx from 'clsx'

import selectValueBasedOnStringHash from '@/shared/helpers/selectValueBasedOnStringHash'

export const getAavatarColor = (name: string): string => {
  const colors = ['#000000', '#78A8FF', '#959BAB', '#3D82FF']
  return selectValueBasedOnStringHash(name, colors)
}

const NamedAvatar: React.FC<{ name: string; className?: string }> = ({ name, className }) => {
  const backgroundColor = getAavatarColor(name)

  let nameLetters = ''
  const [name1, name2] = name.split(' ')
  if (name1 && name2) {
    nameLetters = name1[0] + name2[0]
  } else {
    // eslint-disable-next-line prefer-destructuring
    nameLetters = name1[0]
  }

  return (
    <div
      className={cx('flex h-10 w-10 items-center justify-center text-sm text-white', className)}
      style={{ backgroundColor }}
    >
      <span>{nameLetters}</span>
    </div>
  )
}

export default NamedAvatar
