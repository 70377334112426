import cx from 'clsx'

import { PersonMemo } from '@/modules/task/model'

import SimilarProfile from './SimilarProfile'

const PersonMemoReportView: React.FC<{
  data: PersonMemo.PersonMemoTaskData
  isAnon?: boolean
  className?: string
}> = ({ data, isAnon, className }) => {
  const extendedData = PersonMemo.helpers.extendPersonMemo(data)
  const {
    personSummary,
    companySummary,
    discussionTopics,
    peopleAlsoViewed,
    currentRole,
    descriptionItems,
    educationItems,
    experienceItems,
    accomplishmentsItems,
  } = extendedData

  const similarProfiles = (peopleAlsoViewed || []).slice(0, 3)

  return (
    <div className={cx('flex flex-col gap-8', className)}>
      <section className="report-section flex flex-col gap-6">
        <div className="flex flex-row gap-4 mobile:flex-col">
          {personSummary.linkedin_avatar_url ? (
            <img
              className="h-24 w-24 rounded-full"
              src={personSummary.linkedin_avatar_url}
              alt={personSummary.name}
            />
          ) : null}

          <div>
            <h2 className="font-bold">
              <span className="mr-2 inline-block">{personSummary.name}</span>
              <a
                className="text-base font-normal text-primary"
                href={personSummary.linkedin_url}
                target="_blank"
                rel="noreferrer"
              >
                Open&nbsp;LinkedIn&nbsp;profile
              </a>
            </h2>
            {currentRole ? <p className="mt-2">Current role: {currentRole}</p> : null}
          </div>
        </div>

        {descriptionItems?.length ? (
          <>
            <hr className="border-dashed border-contrast" />

            <h2 className="font-semibold">📝 Description</h2>
            <ul className="flex list-disc flex-col gap-1 pl-4">
              {descriptionItems?.map((item, n) => {
                return <li key={n}>{item}</li>
              })}
            </ul>
          </>
        ) : null}

        {personSummary.sections.length ? (
          <>
            <hr className="border-dashed border-contrast" />

            <div className="flex flex-col gap-4">
              {personSummary.sections.map((section) => {
                return (
                  <div key={section.title}>
                    <h2 className="mb-2 font-semibold">{section.title}</h2>
                    <ul className="flex list-disc flex-col gap-1 pl-4">
                      {section.items.map((item, n) => {
                        return <li key={n}>{item}</li>
                      })}
                    </ul>
                  </div>
                )
              })}
            </div>
          </>
        ) : null}
      </section>

      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        {discussionTopics?.topics.length || discussionTopics?.starters?.length ? (
          <section className="report-section flex flex-col gap-6">
            <h2 className="font-semibold">💬 Discussion Topics and Questions</h2>

            {discussionTopics.starters?.length ? (
              <>
                <p>Conversation starters:</p>
                {discussionTopics.starters.map((starter, n) => {
                  return (
                    <div key={n}>
                      <p className="mb-2 font-semibold">{starter.question}</p>
                      <p className="ml-2 text-sm">
                        <span className="font-semibold">Explanation: </span>
                        {starter.explanation}
                      </p>
                    </div>
                  )
                })}
              </>
            ) : null}

            {discussionTopics.topics.length ? (
              <>
                {' '}
                <p>Recent posts:</p>
                {discussionTopics.topics.map((topic, n) => {
                  return (
                    <div key={n}>
                      <a
                        className="mb-2 block text-base font-normal text-primary"
                        href={topic.post_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>{topic.post_title}</span>
                      </a>

                      {topic.explanation ? <p className="mb-2 pl-2">{topic.explanation}</p> : null}

                      <p className="pl-2">
                        <span>Question: </span>
                        {topic.conversation_starter}
                      </p>
                    </div>
                  )
                })}
              </>
            ) : null}
          </section>
        ) : null}

        {accomplishmentsItems?.length ? (
          <section className="report-section flex flex-col gap-6">
            <h2 className="font-semibold">⭐️ Notable accomplishments </h2>

            <ul className="flex list-disc flex-col gap-1 pl-4">
              {accomplishmentsItems?.map((item, n) => {
                return <li key={n}>{item}</li>
              })}
            </ul>
          </section>
        ) : null}
      </div>

      {companySummary ? (
        <section className="report-section flex flex-col gap-6">
          {companySummary.name ? (
            <h2 className="font-semibold">Company: {companySummary.name}</h2>
          ) : (
            <h2 className="font-semibold">Company Summary</h2>
          )}

          <hr className="border-dashed border-contrast" />

          {companySummary.sections?.map((section) => {
            return (
              <div key={section.title}>
                <h3 className="mb-2 font-bold">{section.title}</h3>
                <ul className="flex flex-col gap-1 pl-4">
                  {section.items?.map((item, n) => {
                    // we assume that the content is a link if it starts with 'https://'
                    if (item.content?.startsWith('https://')) {
                      return (
                        <li key={n} className="break-words">
                          <span className="font-bold">{item.title}: </span>
                          <a
                            className="text-base font-normal text-primary"
                            href={item.content.split(' ')[0]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Open link
                          </a>
                        </li>
                      )
                    }

                    return (
                      <li key={n} className="break-words">
                        <span className="font-bold">{item.title}: </span>
                        <span>{item.content}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}

          {companySummary.open_jobs_section ? (
            <>
              <h3 className="mt-4 font-semibold">Open Jobs</h3>
              {companySummary.open_jobs_section.groups?.map((group, n) => {
                return (
                  <div key={n}>
                    {group.title ? <h4 className="mt-2 font-semibold">{group.title}</h4> : null}
                    <ul className="flex flex-col gap-1 pl-4">
                      {group.items?.map((item, n) => {
                        return (
                          <li key={n} className="break-words">
                            <span className="font-bold">{item.title}: </span>
                            <span>{item.content}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </>
          ) : null}
        </section>
      ) : null}

      {educationItems?.length || experienceItems?.length ? (
        <>
          <h2 className="font-bold">🎓 Education & Experience</h2>
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
            <section className="report-section flex flex-grow flex-col gap-6">
              <h2 className="font-bold">📕 Education</h2>
              <ul className="flex list-disc flex-col gap-1 pl-4">
                {educationItems?.map((item, n) => {
                  return <li key={n}>{item}</li>
                })}
              </ul>
            </section>
            <section className="report-section flex flex-grow flex-col gap-6">
              <h2 className="font-bold">💼 Experience</h2>
              <ul className="flex list-disc flex-col gap-1 pl-4">
                {experienceItems?.map((item, n) => {
                  return <li key={n}>{item}</li>
                })}
              </ul>
            </section>
          </div>
        </>
      ) : null}

      {similarProfiles.length && !isAnon ? (
        <>
          <h2 className="font-semibold">👀 Similar profiles</h2>
          <ul className="flex flex-col gap-4">
            {similarProfiles.map((profile, n) => {
              return <SimilarProfile key={n} profile={profile} />
            })}
          </ul>
        </>
      ) : null}
    </div>
  )
}
export default PersonMemoReportView
