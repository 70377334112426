import { notify } from '@onsaui'
import { useState } from 'react'

import { outreachActions } from '@/modules/icp'
import { OutreachStageType } from '@/modules/icp/model'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { openConnectLinkedInModal } from '@/modules/user'
import useUserMe from '@/modules/user/hooks/useUserMe'
import AnalyticsService from '@/services/analytics'

const useOutreachActions = () => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [isUpdatingMessage, setIsUpdatingMessage] = useState(false)

  const { data: userMe } = useUserMe()
  const hasLinkedInConnection = userMe?.linkedInConnection?.isActive

  const startOutreachAll = async (icpId: string) => {
    if (!hasLinkedInConnection) {
      AnalyticsService.trackEvent('OutreachActions.OutreachAll.UnableToStartNoLinkedInConnection', {
        icpId,
      })

      openConnectLinkedInModal()
      return
    }

    AnalyticsService.trackEvent('OutreachActions.OutreachAll.Start', { icpId })

    setIsProcessing(true)

    try {
      await outreachActions.startOutreachAll(icpId)

      AnalyticsService.trackEvent('OutreachActions.OutreachAll.Success', { icpId })

      notify({ message: 'Outreach started', variant: 'success' })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('OutreachActions.OutreachAll.Error', {
        icpId,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsProcessing(false)
    }
  }

  const startOutreachLead = async (icpId: string, leadId: string) => {
    if (!hasLinkedInConnection) {
      AnalyticsService.trackEvent(
        'OutreachActions.OutreachLead.UnableToStartNoLinkedInConnection',
        {
          icpId,
          leadId,
        },
      )
      openConnectLinkedInModal()
      return
    }

    AnalyticsService.trackEvent('OutreachActions.OutreachLead.Start', { icpId, leadId })

    setIsProcessing(true)

    try {
      await outreachActions.startOutreachLead(icpId, leadId)
      AnalyticsService.trackEvent('OutreachActions.OutreachLead.Success', { icpId, leadId })

      notify({ message: 'Outreach started', variant: 'success' })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('OutreachActions.OutreachLead.Error', {
        icpId,
        leadId,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsProcessing(false)
    }
  }

  const stopOutreachLead = async (icpId: string, leadId: string) => {
    AnalyticsService.trackEvent('OutreachActions.StopOutreachLead.Start', { icpId, leadId })
    setIsProcessing(true)

    try {
      await outreachActions.stopOutreachLead(icpId, leadId)
      AnalyticsService.trackEvent('OutreachActions.StopOutreachLead.Success', { icpId, leadId })

      notify({ message: 'Outreach stopped', variant: 'success' })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('OutreachActions.StopOutreachLead.Error', {
        icpId,
        leadId,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsProcessing(false)
    }
  }

  const updateOutreachStageMessage = async (
    icpId: string,
    leadId: string,
    stageType: OutreachStageType,
    message: string,
  ) => {
    AnalyticsService.trackEvent('OutreachActions.UpdateOutreachStageMessage.Start', {
      icpId,
      leadId,
      stageType,
    })

    setIsUpdatingMessage(true)

    try {
      await outreachActions.updateOutreachStageMessage(icpId, leadId, stageType, message)
      AnalyticsService.trackEvent('OutreachActions.UpdateOutreachStageMessage.Success', {
        icpId,
        leadId,
        stageType,
      })

      notify({ message: 'Message saved', variant: 'success' })
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      AnalyticsService.trackEvent('OutreachActions.UpdateOutreachStageMessage.Error', {
        icpId,
        leadId,
        stageType,
        message: getErrorMessage(e),
      })
      throw e
    } finally {
      setIsUpdatingMessage(false)
    }
  }

  return {
    startOutreachAll,
    startOutreachLead,
    stopOutreachLead,
    isProcessing,
    updateOutreachStageMessage,
    isUpdatingMessage,
  }
}

export default useOutreachActions
