import { useParams } from 'react-router-dom'

import useAuth from '@/modules/auth/hooks/useAuth'
import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import TaskErrorWidget from '@/modules/task/components/TaskErrorWidget/TaskErrorWidget'
import usePublicTask from '@/modules/task/hooks/usePublicTask'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import TaskPage from '../task/TaskPage'

const PublicTaskPage: React.FC = () => {
  const { taskPublicId } = useParams()
  const { isAuth } = useAuth()

  const taskQuery = usePublicTask(taskPublicId!)

  return (
    <RenderQuery
      query={taskQuery}
      renderError={(error) => {
        return (
          <DefaultPageLayout className="flex flex-col items-center justify-center">
            <TaskErrorWidget error={error} />
          </DefaultPageLayout>
        )
      }}
    >
      {(task) => {
        return isAuth ? (
          <TaskPage
            task={task}
            taskContext={{
              withAddToWorkspace: true,
              taskPublicId,
            }}
          />
        ) : (
          <TaskPage task={task} />
        )
      }}
    </RenderQuery>
  )
}

export default PublicTaskPage
