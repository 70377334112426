import cx from 'clsx'

import useOutreachTemplates from '@/modules/icp/hooks/useOutreachTemplates'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import OutreachTemplateItem from '../OutreachTemplatesWidget/OutreachTemplateItem'

const OutreachTemplatesPreviewWidget: React.FC<{ icpId: string; className?: string }> = ({
  icpId,
  className,
}) => {
  const templatesQuery = useOutreachTemplates(icpId)

  return (
    <div className={cx('flex flex-col rounded-3xl bg-level1 p-5', className)}>
      <h3 className="mb-5 font-semibold">Templates</h3>

      <RenderQuery query={templatesQuery}>
        {(templates) => {
          const sortedTemplates = [...(templates ?? [])].sort((a, b) => {
            return b.position - a.position
          })

          if (!sortedTemplates.length) {
            return (
              <div className="flex flex-grow flex-col items-center justify-center">
                <p>No templates have been created yet 🙈</p>
              </div>
            )
          }

          return (
            <div className="flex flex-col gap-5">
              {sortedTemplates.map((template) => (
                <OutreachTemplateItem
                  key={template.id}
                  icpId={icpId}
                  outreachTemplate={template}
                  isViewOnly
                />
              ))}
            </div>
          )
        }}
      </RenderQuery>
    </div>
  )
}

export default OutreachTemplatesPreviewWidget
