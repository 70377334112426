import { create } from 'zustand'

export interface ModalConfig {
  footerAlign?: 'row' | 'col'
  body: React.ReactNode
  confirmLabel?: string
  action?: () => Promise<void>
}

export interface ConfirmModalStore {
  isOpen: boolean

  config?: ModalConfig
  onResult?: (isConfirmed: boolean) => void
}

export const useConfirmModalStore = create<ConfirmModalStore>((set) => ({
  isOpen: false,

  config: undefined,
  onResult: undefined,
}))

export const openConfirmModal = (config: ModalConfig) => {
  return new Promise<boolean>((resolve) => {
    useConfirmModalStore.setState({ isOpen: true, config, onResult: resolve })
  })
}

export const closeConfirmModal = (isConfirmed: boolean) => {
  const state = useConfirmModalStore.getState()
  if (!state.isOpen) {
    return
  }

  state.onResult?.(isConfirmed)
  useConfirmModalStore.setState({
    isOpen: false,

    config: undefined,
    onResult: undefined,
  })
}
