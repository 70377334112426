import { IllustrationReport } from '@onsaui/icons'

import CreateTaskCard from '@/modules/general/components/CreateTaskCard/CreateTaskCard'
import { TranscriptReport } from '@/modules/task/model'

import CreateTranscriptReportTaskForm from '../CreateTranscriptReportTaskForm/CreateTranscriptReportTaskForm'

const CreateTranscriptReportTaskCard: React.FC<{
  onTaskCreated?: (task: TranscriptReport.Task) => void
  className?: string
  isAnon?: boolean
  creditsCost?: number
}> = ({ onTaskCreated, className, isAnon, creditsCost }) => {
  return (
    <CreateTaskCard
      className={className}
      price={creditsCost}
      Illustration={IllustrationReport}
      title="Get an Eval Report"
      description="Acessable formats: txt, mp3, m4a, webm, mp4, wav"
      buttonLabel="Get Report"
      modalContent={
        <CreateTranscriptReportTaskForm onTaskCreated={onTaskCreated} isAnon={isAnon} />
      }
    />
  )
}

export default CreateTranscriptReportTaskCard
