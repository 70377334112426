import { useQuery } from '@tanstack/react-query'

import { outreachApi } from '@/modules/icp/api'

const useOutreachDetails = (icpId: string, leadId: string) => {
  return useQuery({
    queryKey: ['outreachDetails', icpId, leadId],
    queryFn: () => {
      return outreachApi.getOutreachDetails(icpId, leadId)
    },
  })
}

export default useOutreachDetails
