import { TextArea } from '@onsaui'
import cx from 'clsx'
import { useEffect, useState } from 'react'

import useOutreachActions from '@/modules/icp/hooks/useOutreachActions'
import { OutreachStage } from '@/modules/icp/model'

const EditableStageMessage: React.FC<{
  icpId: string
  leadId: string
  stage: OutreachStage
  isEditable?: boolean
  className?: string
}> = ({ icpId, leadId, stage, isEditable, className }) => {
  const [message, setMessage] = useState(stage.message ?? '')
  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  useEffect(() => {
    setMessage(stage.message ?? '')
  }, [stage.message])

  const { updateOutreachStageMessage, isUpdatingMessage } = useOutreachActions()

  const handleSave = () => {
    if (message === stage.message) {
      return
    }

    updateOutreachStageMessage(icpId, leadId, stage.type, message)
  }

  if (!isEditable) {
    return (
      <p
        className={cx(
          'whitespace-pre-wrap rounded-[4px] bg-disabled-surface p-5 text-secondary',
          className,
        )}
      >
        {stage.message}
      </p>
    )
  }

  return (
    <TextArea
      variant="primary-filled"
      className={cx('', className)}
      inputClassName="!rounded-[4px]"
      placeholder="Message"
      autoHeight={{ enabled: true, minLines: 3, maxLines: 12 }}
      onChange={handleMessageChange}
      value={message}
      onBlur={handleSave}
      isDisabled={isUpdatingMessage}
    />
  )
}

export default EditableStageMessage
