import { Navigate, useParams } from 'react-router-dom'

import useIcp from '@/modules/icp/hooks/useIcp'
import { icpHelpers, IcpStage } from '@/modules/icp/model'

const IcpEditRedirect: React.FC = () => {
  const { workspaceId, icpId } = useParams()
  const { data: icp } = useIcp(icpId!)

  if (!icp) {
    return null
  }

  if (icp.stage === IcpStage.Finalized) {
    return <Navigate to={`/${workspaceId}/icp/${icpId}`} replace />
  }

  return <Navigate to={icpHelpers.getIcpStageRoute(icp.stage)} replace />
}

export default IcpEditRedirect
