import { IconClose } from '@onsaui/icons'
import cx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import cm from './CancellableActionButton.module.css'

const SAFE_ADDITION_TIME = 400

const CancellableActionButton: React.FC<{
  onAction?: () => void
  color?: 'primary' | 'danger'
  cancelTime?: number
  className?: string
  onClick?: () => void
  children: React.ReactNode
}> = ({ onAction, color = 'danger', cancelTime = 3000, onClick, className, children }) => {
  const intervalRef = useRef<number | null>(null)
  const [progress, setProgress] = useState(0)
  const [deleteTime, setDeleteTime] = useState(0)

  const handleSoftDelete = () => {
    if (deleteTime) {
      return
    }

    onClick?.()

    setDeleteTime(Date.now())
    setProgress(0)
  }

  useEffect(() => {
    if (deleteTime) {
      intervalRef.current = window.setInterval(() => {
        const currentTime = Date.now()
        const diff = currentTime - deleteTime
        if (diff >= cancelTime + SAFE_ADDITION_TIME) {
          clearInterval(intervalRef.current!)
          intervalRef.current = null
          setProgress(1)

          onAction?.()
        } else {
          setProgress(diff / cancelTime)
        }
      }, 30)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTime])

  const handleSoftCancel = () => {
    setDeleteTime(0)
    setProgress(0)

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  const cssColor =
    color === 'primary' ? 'hsl(var(--color-primary-hsl) / 1)' : 'hsl(var(--color-danger-hsl) / 1)'

  const size = 24
  const strokeWidth = 4

  if (deleteTime) {
    return (
      <button
        type="button"
        className={cx(
          'group relative flex min-h-6 min-w-6 shrink-0 flex-col items-center justify-center',
          className,
        )}
        onClick={handleSoftCancel}
        aria-label="Cancel"
      >
        <svg
          width={size}
          height={size}
          viewBox={`0 0 ${size} ${size}`}
          className={cx(cm['circular-progress'], 'shrink-0')}
          style={
            {
              '--bg-color': 'var(--color-disabled)',
              '--action-color': cssColor,
              '--progress': progress * 100,
              '--size': `${size}px`,
              '--stroke-width': `${strokeWidth}px`,
            } as React.CSSProperties
          }
        >
          <circle className={cm.bg} />
          <circle className={cm.fg} />
        </svg>

        <div className="absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 rounded-full text-secondary transition-colors group-hover:text-danger">
          <IconClose className="h-3 w-3" />
        </div>
      </button>
    )
  }

  return (
    <button
      className={cx(
        'transition-colors',
        { 'hover:text-primary': color === 'primary', 'hover:text-danger': color === 'danger' },
        className,
      )}
      type="button"
      onClick={handleSoftDelete}
    >
      {children}
    </button>
  )
}

export default CancellableActionButton
