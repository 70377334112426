import { InfoTooltip } from '@onsaui'
import cx from 'clsx'

import useIcpReferenceLeads from '@/modules/icp/hooks/useIcpReferenceLeads'
import { icpHelpers } from '@/modules/icp/model'

const IcpReferenceScore: React.FC<{ icpId: string; className?: string }> = ({
  icpId,
  className,
}) => {
  const { data: leadsWithStatus } = useIcpReferenceLeads(icpId)

  const { score, isGood } = leadsWithStatus
    ? icpHelpers.getLeadsScore(leadsWithStatus.leads)
    : { score: 0, isGood: false }

  return (
    <InfoTooltip
      align="end"
      content={
        <div className="flex w-[300px] flex-row gap-3 rounded-2xl bg-level1 p-5 text-sm shadow-card">
          <div
            className={cx('h-4 w-4 shrink-0 rounded-full', {
              'bg-[#75F6A2]': isGood,
              'bg-danger/50': !isGood,
            })}
          />
          <div>
            <p className="mb-2 font-semibold">
              {isGood
                ? 'Your Average References Score is good enough!'
                : 'Your Average References Score needs attention...'}
            </p>
            {isGood ? (
              <p>Better Score better ICP and more relevant prospects you will get in the end.</p>
            ) : (
              <>
                <p>
                  A better score means a better ICP and more relevant prospects. Take action now to
                  improve your results!
                </p>
                <p className="mt-2">
                  <span className="font-semibold">Generate more leads</span> if there are no
                  relevant in the list.
                </p>
              </>
            )}
          </div>
        </div>
      }
    >
      <div
        className={cx(
          'inline-flex h-10 cursor-help flex-row items-center gap-1 rounded-[10px] px-4 py-1.5 text-sm',
          {
            'bg-[#75F6A2]': isGood,
            'bg-danger/50': !isGood,
          },
          className,
        )}
      >
        <span>{isGood ? 'Good' : 'Low'}</span>
        {score}
      </div>
    </InfoTooltip>
  )
}

export default IcpReferenceScore
