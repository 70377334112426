import { BeautyLoader } from '@onsaui'
import cx from 'clsx'
import { useEffect, useState } from 'react'

import cm from './IcpLoader.module.css'

type LoaderStage =
  | 'context'
  | 'update'
  | 'reference-leads'
  | 'templates'
  | 'confirmation'
  | 'campaign'

const StageConfig: Record<LoaderStage, { labels: string[] }> = {
  context: {
    labels: [
      'Scanning the web for your next big opportunity...',
      'Hold tight—crafting your Ideal Customer Profile masterpiece!',
      'Turning data into insights—your ICP is coming right up!',
      'Piecing together the puzzle of your ideal customer...',
    ],
  },
  update: {
    labels: [
      'Incorporating your insights—refining your Ideal Customer Profile...',
      'Integrating new data points—making your ICP even smarter...',
      'Merging your input with our insights—personalizing your ICP like never before!',
      'Re-calibrating the strategy—your Ideal Customer Profile is evolving...',
    ],
  },
  'reference-leads': {
    labels: [
      'Generating actionable profiles—stay tuned for brilliance!',
      'Building connections between your brand and the right audience...',
      "Tweaking and tuning for a profile that's perfectly aligned with your goals!",
      'Connecting the dots—validating prospect credentials for your approval!',
    ],
  },
  templates: {
    labels: [
      'Designing templates that spark conversations with your prospects...',
      'Finding the right words to connect with your ideal leads—almost ready!',
      'Focusing on clarity, creativity, and connection—your templates are on the way!',
      'Creating impactful templates to help you break the ice...',
    ],
  },
  confirmation: {
    labels: [
      'Analyzing fresh context to fine-tune your customer vision...',
      'Hold tight—bringing the final elements together for your ICP masterpiece...',
      'Optimizing the last details—your ICP is almost ready to roll!',
      'Fine-tuning your insights—wrapping up the final steps...',
    ],
  },
  campaign: {
    labels: [
      'The wait is over—loading your prospect leads based on the perfect ICP...',
      'Compiling a tailored list of leads that match your Ideal Customer Profile...',
      'Your customer discovery journey is progressing—prospects incoming!',
      'Bringing you quality over quantity—prospects aligned with your ICP...',
    ],
  },
}

const LABEL_DURATION = 12000

const IcpLoader: React.FC<{ stage: LoaderStage; className?: string }> = ({ stage, className }) => {
  const config = StageConfig[stage]
  const { labels } = config

  const [label, setLabel] = useState(labels[0])
  const [prevLabel, setPrevLabel] = useState('')
  useEffect(() => {
    const config = StageConfig[stage]
    const labelIndex = config.labels.indexOf(label)
    const nextLabel = config.labels[labelIndex + 1] || config.labels[0]

    const interval = window.setTimeout(() => {
      setPrevLabel(label)
      setTimeout(() => setPrevLabel(''), 310)

      setLabel(nextLabel)
    }, LABEL_DURATION)

    return () => window.clearTimeout(interval)
  }, [stage, label])

  return (
    <div className={cx('flex max-w-[340px] flex-col items-center text-center', className)}>
      <BeautyLoader />
      <div className="relative mt-6">
        {prevLabel ? (
          <p className={cx('absolute top-0', cm['fade-out-left'])}>{prevLabel}</p>
        ) : null}
        <p key={label} className={cx(cm['fade-in-left'])}>
          {label}
        </p>
      </div>
    </div>
  )
}

export default IcpLoader
