import { useParams } from 'react-router-dom'

import { InternalTaskType } from '@/modules/task/model'
import useWorkspace from '@/modules/workspace/hooks/useWorkspace'

const useIcpEnabled = () => {
  const { workspaceId } = useParams()

  const { data: workspace } = useWorkspace(workspaceId!)

  const isIcpEnabled =
    workspace?.availableTasks.some((config) => config.type === InternalTaskType.CreateIcp) ?? false

  return { isIcpEnabled }
}

export default useIcpEnabled
