import { Button, IfScreen } from '@onsaui'
import { IconArrowRightSm } from '@onsaui/icons'
import cx from 'clsx'
import dayjs from 'dayjs'

import useOutreachActions from '@/modules/icp/hooks/useOutreachActions'
import useOutreachDetails from '@/modules/icp/hooks/useOutreachDetails'
import { OutreachStage, OutreachStageStatus, OutreachStageType } from '@/modules/icp/model'
import AnalyticsService from '@/services/analytics'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import LeadMemoButton from './LeadMemoButton'
import OutreachStageItem from './OutreachStageItem'

const getStageWaitLabel = (stage: OutreachStage) => {
  const isFinitStatus = (status?: OutreachStageStatus) => {
    return (
      status &&
      (status === OutreachStageStatus.Positive ||
        status === OutreachStageStatus.Negative ||
        status === OutreachStageStatus.StoppedByUser ||
        status === OutreachStageStatus.StoppedByOnsa)
    )
  }

  if (isFinitStatus(stage.status)) {
    return null
  }

  if (stage.type === OutreachStageType.Connect) {
    const connectionDelay = stage.waitUntil || dayjs().add(30, 'day')

    return `Waiting for connection acceptance for ${connectionDelay.fromNow(true)}`
  }
  if (stage.type === OutreachStageType.Outreach) {
    const followupDelay = stage.waitUntil || dayjs().add(7, 'day')
    return `First follow up ${followupDelay.fromNow()}`
  }
  if (stage.type === OutreachStageType.FollowUp1) {
    const followupDelay = stage.waitUntil || dayjs().add(7, 'day')
    return `Second follow up ${followupDelay.fromNow()}`
  }

  return null
}

const OutreachDetailsWidget: React.FC<{ icpId: string; leadId: string; className?: string }> = ({
  icpId,
  leadId,
  className,
}) => {
  const detailsQuery = useOutreachDetails(icpId, leadId)
  const details = detailsQuery.data

  const { startOutreachLead, stopOutreachLead, isProcessing } = useOutreachActions()

  const handleStartOutreach = () => {
    AnalyticsService.trackEvent('OutreachDetailsWidget.BtnStartOutreach.Click', { icpId, leadId })
    startOutreachLead(icpId, leadId)
  }
  const handleStopOutreach = () => {
    AnalyticsService.trackEvent('OutreachDetailsWidget.BtnStopOutreach.Click', { icpId, leadId })
    stopOutreachLead(icpId, leadId)
  }

  const button =
    details && !details.isRunning && !details.isTerminalState ? (
      <Button
        variant="outlined"
        size="medium"
        onClick={handleStartOutreach}
        isLoading={isProcessing}
        isDisabled={isProcessing}
      >
        Start Outreach
      </Button>
    ) : details && details.isRunning && !details.isTerminalState ? (
      <Button
        variant="outlined"
        size="medium"
        onClick={handleStopOutreach}
        isLoading={isProcessing}
        isDisabled={isProcessing}
      >
        Stop Outreach
      </Button>
    ) : null

  return (
    <RenderQuery query={detailsQuery} className={className}>
      {(outreachDetails) => {
        return (
          <div className={cx('flex min-h-[300px] flex-col rounded-3xl bg-level1 p-5', className)}>
            <div className="mb-8 flex min-h-10 flex-row items-start">
              <div>
                <div className="mb-2 flex flex-row items-center">
                  <a
                    className="block text-h3 text-primary"
                    href={outreachDetails.linkedInUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {outreachDetails.fullName}
                  </a>
                  <LeadMemoButton
                    className="ml-2"
                    icpId={icpId}
                    leadId={leadId}
                    memoId={outreachDetails.memoId}
                  />
                </div>
                {outreachDetails.position ? (
                  <p className="text-sm">Current role: {outreachDetails.position}</p>
                ) : null}
                {outreachDetails.companyName ? (
                  <p className="text-sm">
                    Company:
                    <a
                      className="ml-1 text-primary"
                      href={outreachDetails.companyLinkedInUrl}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {outreachDetails.companyName}
                    </a>
                  </p>
                ) : null}
              </div>

              <div className="flex-grow" />

              <IfScreen desktop={button} />
            </div>
            <IfScreen mobile={button} />

            <div className="flex flex-col gap-8 mobile:mt-6">
              {outreachDetails.stages.map((stage, n) => {
                if (outreachDetails.isTerminalState && !stage.status) {
                  return null
                }

                const waitLabel = getStageWaitLabel(stage)

                return (
                  <>
                    <OutreachStageItem
                      key={stage.type}
                      icpId={icpId}
                      leadId={leadId}
                      stage={stage}
                    />

                    {waitLabel ? (
                      <div className="self-center rounded-[10px] bg-disabled-surface px-3 py-2 text-center text-secondary">
                        {waitLabel}
                      </div>
                    ) : null}
                    {stage.replyMessage ? (
                      <div className="self-end">
                        <div className="max-w-sm rounded-[10px] bg-success-surface p-5">
                          <p>{stage.replyMessage}</p>
                        </div>
                        <Button className="float-right mt-2" asChild>
                          <a
                            href={outreachDetails.linkedInUrl}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Reply <IconArrowRightSm className="ml-1" />
                          </a>
                        </Button>
                      </div>
                    ) : null}
                  </>
                )
              })}
            </div>
          </div>
        )
      }}
    </RenderQuery>
  )
}

export default OutreachDetailsWidget
