import { IconClose } from '@onsaui/icons'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import cx from 'clsx'
import React, { useCallback } from 'react'

import cm from './Modal.module.css'

const Modal = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  {
    children: React.ReactNode
    isOpen?: boolean
    onClose?: () => void
    className?: string
  }
>(({ children, isOpen, onClose, className }, ref) => {
  const handleInteractOutside = useCallback(
    (event: Event) => {
      if (event.defaultPrevented) {
        return
      }

      onClose?.()
    },
    [onClose],
  )

  const handleEscapeKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.defaultPrevented) {
        return
      }

      onClose?.()
    },
    [onClose],
  )

  const handleCloseClick = useCallback(
    (event: React.MouseEvent) => {
      if (event.defaultPrevented) {
        return
      }

      onClose?.()
    },
    [onClose],
  )

  return (
    <DialogPrimitive.Root open={isOpen} modal>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className={cx('fixed inset-0 z-[99] overflow-auto', cm.overlay)}>
          <div className="relative top-[12%] flex max-w-full flex-col items-center px-4 pb-12">
            <DialogPrimitive.Content
              className={cx(
                'relative rounded-[20px] bg-level1 px-10 pb-8 pt-8',
                cm.modalContent,
                className,
              )}
              ref={ref}
              onInteractOutside={handleInteractOutside}
              onEscapeKeyDown={handleEscapeKeyDown}
            >
              <DialogPrimitive.Close
                className="absolute right-4 top-4 transition-colors hover:text-primary"
                onClick={handleCloseClick}
              >
                <IconClose />
              </DialogPrimitive.Close>

              {children}
            </DialogPrimitive.Content>
          </div>
        </DialogPrimitive.Overlay>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
})
Modal.displayName = 'ModalOverlay'

export { Modal }
