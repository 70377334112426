import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import DefaultPageLayout from '@/modules/general/components/DefaultPageLayout/DefaultPageLayout'
import EnsureProcessedIcp from '@/modules/icp/components/EnsureProcessedIcp/EnsureProcessedIcp'
import IcpBody from '@/modules/icp/components/IcpContextWidget/IcpBody'
import IcpContext from '@/modules/icp/components/IcpContextWidget/IcpContext'
import IcpLoader from '@/modules/icp/components/IcpLoader/IcpLoader'
import IcpStageWidget from '@/modules/icp/components/IcpStageWidget/IcpStageWidget'
import { IcpStage } from '@/modules/icp/model'
import AnalyticsService from '@/services/analytics'

const IcpContextPage: React.FC = () => {
  useEffect(() => {
    AnalyticsService.trackEvent('IcpContextPage.View')
  }, [])

  const { icpId } = useParams()

  return (
    <DefaultPageLayout>
      <IcpStageWidget icpId={icpId!} currentStage={IcpStage.SetContext} className="mb-5" />

      <EnsureProcessedIcp
        icpId={icpId!}
        // eslint-disable-next-line react/no-unstable-nested-components
        loader={(icp) => (icp.body ? <IcpLoader stage="update" /> : <IcpLoader stage="context" />)}
      >
        {(icp) => {
          return (
            <div className="flex flex-col gap-6 xl:flex-row">
              <IcpBody icp={icp} className="flex-grow" />
              <IcpContext icp={icp} className="xl:self-start xl:pt-6" />
            </div>
          )
        }}
      </EnsureProcessedIcp>
    </DefaultPageLayout>
  )
}

export default IcpContextPage
