import { Button, IfScreen, Loader } from '@onsaui'
import { IconPlusSm } from '@onsaui/icons'
import cx from 'clsx'
import { useState } from 'react'

import useIcpCampaignLeads from '@/modules/icp/hooks/useIcpCampaignLeads'
import useIcpLeadsActions from '@/modules/icp/hooks/useIcpLeadsActions'
import useOutreachActions from '@/modules/icp/hooks/useOutreachActions'
import { TaskStatus } from '@/modules/task/model'
import AnalyticsService from '@/services/analytics'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

import IcpLoader from '../IcpLoader/IcpLoader'
import OutreachAllConfirmModal from '../OutreachAllConfirmModal/OutreachAllConfirmModal'
import CampaignLeadItem from './CampaignLeadItem'
import useScrollRestoration from './useScrollRestoration'

const IcpCampaignLeadsWidget: React.FC<{
  icpId: string
  icpTitle?: string
  className?: string
}> = ({ icpId, icpTitle, className }) => {
  const leadsQuery = useIcpCampaignLeads(icpId)
  const { generateIcpCampaignLeads, isGenerating, exportCampaignLeadsAsCsv, isExporting } =
    useIcpLeadsActions()

  const { startOutreachAll, isProcessing } = useOutreachActions()

  const handleGenerateLeads = async () => {
    AnalyticsService.trackEvent('IcpCampaignLeadsWidget.BtnGenerateMoreLeads.Click')
    await generateIcpCampaignLeads(icpId)
  }

  const handleExportAsCsv = async () => {
    AnalyticsService.trackEvent('IcpCampaignLeadsWidget.BtnExportAsCsv.Click')
    const snakeCaseName = icpTitle?.replace(/\s/g, '_').toLowerCase()
    exportCampaignLeadsAsCsv(icpId, snakeCaseName)
  }

  const [isOutreachAllConfirmModalOpen, setIsOutreachAllConfirmModalOpen] = useState(false)
  const handleOpenOutreachAllConfirmModal = () => {
    AnalyticsService.trackEvent('IcpCampaignLeadsWidget.BtnStartOutreach.Click')

    setIsOutreachAllConfirmModalOpen(true)
  }
  const handleCloseOutreachAllConfirmModal = () => {
    setIsOutreachAllConfirmModalOpen(false)
  }
  const handleOutreachAll = () => {
    handleCloseOutreachAllConfirmModal()

    startOutreachAll(icpId)
  }

  useScrollRestoration()

  const leadsToOutreach = leadsQuery.data?.leads.filter((lead) => !lead.outreachStage) ?? []

  const buttonStartOutreach = leadsToOutreach.length ? (
    <Button
      className="mobile:mb-6"
      variant="outlined"
      size="medium"
      onClick={handleOpenOutreachAllConfirmModal}
      isLoading={isProcessing}
      isDisabled={isProcessing}
    >
      Start Outreach
    </Button>
  ) : null

  return (
    <div className={cx('flex flex-col rounded-3xl bg-level1 p-5', className)}>
      <OutreachAllConfirmModal
        leadsCount={leadsToOutreach.length}
        isOpen={isOutreachAllConfirmModalOpen}
        onClose={handleCloseOutreachAllConfirmModal}
        onConfirm={handleOutreachAll}
      />
      <div className="mb-3 flex min-h-10 flex-row items-center">
        <h3 className="font-semibold">Prospective Leads</h3>
        <div className="flex-grow" />
        {leadsQuery.data?.leads.length ? (
          <Button
            variant="ghost"
            size="medium"
            onClick={handleExportAsCsv}
            isDisabled={isExporting}
          >
            Export as .csv
          </Button>
        ) : null}
        <IfScreen desktop={buttonStartOutreach} />
      </div>
      <IfScreen mobile={buttonStartOutreach} />

      <RenderQuery query={leadsQuery}>
        {(leadsWithStatus) => {
          if (leadsWithStatus.status === TaskStatus.Failed) {
            return (
              <div className="flex flex-grow flex-col items-center justify-center">
                <p className="mb-1 text-danger">Leads generation failed</p>
                <Button
                  size="medium"
                  className="self-center"
                  onClick={handleGenerateLeads}
                  isDisabled={isGenerating}
                  isLoading={isGenerating}
                >
                  Try Again
                </Button>
              </div>
            )
          }
          if (
            leadsWithStatus.status !== TaskStatus.Completed &&
            leadsWithStatus.leads.length === 0
          ) {
            return (
              <div className="flex flex-grow flex-col items-center justify-center">
                <IcpLoader stage="campaign" />
              </div>
            )
          }

          if (leadsWithStatus.leads.length === 0) {
            return (
              <div className="flex flex-grow flex-col items-center justify-center">
                <p className="mb-2">Leads haven&apos;t generated yet</p>
                <Button
                  onClick={handleGenerateLeads}
                  isDisabled={isGenerating}
                  isLoading={isGenerating}
                >
                  Generate
                </Button>
              </div>
            )
          }

          return (
            <>
              <div className="flex flex-col gap-3">
                {leadsWithStatus.leads.map((lead) => (
                  <CampaignLeadItem key={lead.id} icpId={icpId} lead={lead} />
                ))}
              </div>

              {leadsWithStatus.status === TaskStatus.Completed ? (
                <Button
                  size="medium"
                  className="mt-4 self-center"
                  onClick={handleGenerateLeads}
                  isDisabled={isGenerating}
                  isLoading={isGenerating}
                >
                  Generate more leads
                  <IconPlusSm className="ml-1" />
                </Button>
              ) : (
                <div className="mt-4 flex flex-col items-center justify-center py-4">
                  <p className="mb-4"> Loading more leads</p>
                  <Loader />
                </div>
              )}
            </>
          )
        }}
      </RenderQuery>
    </div>
  )
}

export default IcpCampaignLeadsWidget
