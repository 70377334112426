import { Icp, IcpStatistics } from '@/modules/icp/model'

import { IcpResponse, IcpStatisticsResponse } from './icpApiInterfaces'

export const icpAdapter = (icpResponse: IcpResponse) => {
  const icp: Icp = {
    ...icpResponse,
    context: icpResponse.context ?? '',
    changelog: icpResponse.changelog ?? '',
  }

  return icp
}

export const icpStatisticsAdapter = (statisticsResponse: IcpStatisticsResponse) => {
  const statistics: IcpStatistics = {
    outreached: statisticsResponse.contacted,
    connected: statisticsResponse.accepted,
    replied: statisticsResponse.responded,
    successRate: statisticsResponse.successRate,
  }

  return statistics
}
