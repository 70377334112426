import cx from 'clsx'

import { IcpLead } from '@/modules/icp/model'
import NamedAvatar from '@/shared/component/NamedAvatar/NamedAvatar'

const LeadPreviewItem: React.FC<{
  icpLead: IcpLead
  className?: string
}> = ({ icpLead, className }) => {
  return (
    <div
      className={cx(
        'flex flex-row items-start gap-4 rounded-3xl border border-disabled p-4',
        className,
      )}
    >
      <NamedAvatar name={icpLead.fullName} className="h-10 w-10 shrink-0 self-start rounded-full" />

      <div className="w-full max-w-[320px]">
        <div className="flex flex-row">
          <a className="text-primary" href={icpLead.linkedInUrl} target="_blank" rel="noreferrer">
            {icpLead.fullName}
          </a>
        </div>

        <div className="mt-3 text-sm">
          <p className="text-secondary">Current role</p>
          <p>{icpLead.position}</p>
        </div>
        <div className="mt-3 text-sm">
          <p className="text-secondary">Company</p>
          <a
            className="block text-primary"
            href={icpLead.companyLinkedInUrl}
            target="_blank"
            rel="noreferrer"
          >
            {icpLead.companyName}
          </a>
        </div>
      </div>

      <div className="w-full max-w-[300px]">
        <div className="inline-block rounded-[10px] bg-disabled-surface px-4 py-2">
          <p>
            Score <span className="font-bold">{icpLead.score}</span>/5
          </p>
        </div>

        {icpLead.comment ? (
          <div className="mt-2 rounded-[10px] bg-disabled-surface px-4 py-2">
            <p className="text-sm">
              <span className="text-secondary">Reason: </span>
              <span>{icpLead.comment}</span>
            </p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default LeadPreviewItem
