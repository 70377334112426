import { Button, Modal, notify } from '@onsaui'
import { IconLinkedIn } from '@onsaui/icons'
import { useEffect, useState } from 'react'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { closeConnectLinkedInModal, userActions, useUserStore } from '@/modules/user'
import AnalyticsService from '@/services/analytics'

const ConnectLinkedInModal: React.FC = () => {
  const { isConnectLinkedInModalOpen } = useUserStore()

  useEffect(() => {
    if (isConnectLinkedInModalOpen) {
      AnalyticsService.trackEvent('ConnectLinkedInModal.Show')
    }
  }, [isConnectLinkedInModalOpen])

  const handleClose = () => {
    AnalyticsService.trackEvent('ConnectLinkedInModal.BtnClose.Click')
    closeConnectLinkedInModal()
  }

  const [isConnecting, setIsConnecting] = useState(false)
  const handleConnect = async () => {
    AnalyticsService.trackEvent('ConnectLinkedInModal.BtnConnect.Click')

    setIsConnecting(true)
    try {
      await userActions.connectLinkedIn()
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsConnecting(false)
    }
  }

  return (
    <Modal
      className="flex max-w-md flex-col items-center text-center"
      isOpen={isConnectLinkedInModalOpen}
      onClose={handleClose}
    >
      <IconLinkedIn className="mb-4 h-24 w-24" />
      <h2 className="mb-2 font-semibold">Connect your Linkedin account</h2>

      <p className="mb-10 text-sm text-secondary">
        Link your LinkedIn account to send messages directly through our platform. Sync your
        profile, expand your network, and streamline communication with your connections.
      </p>

      <Button
        className="mb-2 w-full"
        onClick={handleConnect}
        isLoading={isConnecting}
        isDisabled={isConnecting}
      >
        Continue
      </Button>
      <Button className="w-full" variant="ghost" onClick={handleClose}>
        Cancel
      </Button>
    </Modal>
  )
}

export default ConnectLinkedInModal
